export const getDisplayedTimeAgo = (createdAtString: string, short = false) => {
  const createdAt = new Date(createdAtString);
  const now = new Date();
  const diffMs = now.getTime() - createdAt.getTime();
  const diffSecs = Math.floor(diffMs / 1000);
  const diffMins = Math.floor(diffSecs / 60);
  const diffHrs = Math.floor(diffMins / 60);

  if (isNaN(createdAt.getTime())) {
    return "Invalid date";
  }

  if (diffSecs < 60) {
    return short ? `${diffSecs}s` : "Just now";
  } else if (diffMins < 60) {
    return short
      ? `${diffMins}m`
      : `${diffMins} minute${diffMins > 1 ? "s" : ""} ago`;
  } else if (diffHrs < 24) {
    return short
      ? `${diffHrs}h`
      : `${diffHrs} hour${diffHrs > 1 ? "s" : ""} ago`;
  } else {
    // For days, consider showing the difference in days for the short version
    const diffDays = Math.floor(diffHrs / 24);
    if (short) {
      return diffDays < 7 ? `${diffDays}d` : createdAt.toLocaleDateString();
    } else {
      return createdAt.toLocaleDateString();
    }
  }
};

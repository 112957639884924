"use client";

import { useState } from "react";
import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";

import { useStyles } from "./styles";
import { useAppSelector } from "@/redux/store";
import { useLocalStorage } from "@/hooks/useStorage";
import Button from "@/components/common/PrimaryButton";
import DropdownMenu from "./DropdownMenu";
import Avatar from "@/components/common/Avatar";

const UserControls = () => {
  const { classes } = useStyles();
  const pathname = usePathname() as string;
  const searchParams = useSearchParams();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const user = useAppSelector((state) => state.user.user);
  const isSubscribed = Boolean(user && user.subscriptionType !== "free");
  const authLoading = useAppSelector((state) => state.user.authLoading);
  const [_, setRedirectPath] = useLocalStorage("redirectPath", "/");

  const showMenu = Boolean(menuAnchorEl);

  const handleOpenMenu = () => {
    const navbar = document.getElementById("navbar");
    if (!navbar) return;
    setMenuAnchorEl(navbar);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleLoginClick = () => {
    const redirectPath = `${pathname}${searchParams ? `?${searchParams}` : ""}`;
    setRedirectPath(redirectPath);
  };

  const placeholder = !authLoading ? (
    <Link href="/signin" onClick={handleLoginClick}>
      <Button color="secondary">Login</Button>
    </Link>
  ) : null;

  if (!user) return placeholder;

  return (
    <div className={classes.container}>
      <div className={classes.userDetails}>
        <p className={classes.username}>{user.username}</p>
        <div className={classes.subscriptionDetails}>
          {!isSubscribed && (
            <Link href="/subscribe">
              <Button color="secondary" className={classes.upgradeButton}>
                Upgrade
              </Button>
            </Link>
          )}
          <p className={classes.subscriptionLabel}>{user.subscriptionType}</p>
        </div>
      </div>
      <Avatar
        src={user.avatarUrl || ""}
        alt={user.username}
        className={classes.avatar}
        onClick={handleOpenMenu}
      />
      <DropdownMenu
        anchorEl={menuAnchorEl}
        user={user}
        open={showMenu}
        onClose={handleCloseMenu}
      />
    </div>
  );
};

export default UserControls;

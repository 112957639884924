"use client";

import { useRef, useState } from "react";
import { useParams, useRouter } from "next/navigation";

import { useStyles } from "./styles";
import { Point } from "@/types/common";
import { AlertDialogType } from "@/types/alert";
import { TransformationType } from "@/types/thread";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  setSelectedModel,
  createRoom,
  getNewGenerations,
  addAlertDialog,
} from "@/redux/actions";
import { useGenContext } from "@/context/GenContext";
import Modal from "@/components/common/Modal";
import InpaintCanvas, { InpaintCanvasHandle } from "./InpaintCanvas";
import TextField from "@/components/common/TextField";
import Button from "@/components/common/PrimaryButton";

const InpaintModal = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { roomUUID } = useParams();
  const { state, dispatch: genDispatch } = useGenContext();
  const { strokes, selectedImage, isEditingMask, images, prompt, isDrawing } =
    state;
  const canvasRef = useRef<InpaintCanvasHandle>(null);
  const [generatingMask, setGeneratingMask] = useState(false);
  const generations = useAppSelector((state) => state.room.generations);
  const quotas = useAppSelector((state) => state.user.quotas);
  const inpaint = quotas.find((quota) => quota.resourceName === "inPaint");
  const dailyInpaintUsed = inpaint ? (inpaint.currentQuota ?? 0) <= 0 : false;

  const handleUpdatePrompt = (newValue: string) => {
    genDispatch({ type: "SET_PROMPT", payload: newValue });
  };

  const setStrokes = (strokes: Point[][]) => {
    genDispatch({ type: "SET_STROKES", payload: strokes });
  };

  const onDrawingChange = (isDrawing: boolean) => {
    genDispatch({ type: "SET_IS_DRAWING", payload: isDrawing });
  };

  const loadImage = (src: string | Blob): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      if (src instanceof Blob) {
        const objectUrl = URL.createObjectURL(src);
        img.src = objectUrl;
        img.onload = () => {
          URL.revokeObjectURL(objectUrl);
          resolve(img);
        };
        img.onerror = (err) => {
          URL.revokeObjectURL(objectUrl);
          reject(err);
        };
      } else {
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
      }
    });
  };

  const handleCancelInpainting = () => {
    genDispatch({ type: "SET_SELECTED_IMAGE", payload: null });
    genDispatch({ type: "SET_STROKES", payload: [] });
    genDispatch({ type: "SET_IS_EDITING_MASK", payload: false });
  };

  const generateMaskImage = async (): Promise<Blob | null> => {
    if (canvasRef.current && selectedImage) {
      setGeneratingMask(true);
      try {
        const img = await loadImage(selectedImage);
        const originalImageWidth = img.width;
        const originalImageHeight = img.height;

        if (!canvasRef.current) return null;

        const blob = await canvasRef.current.generateMaskImage(
          originalImageWidth,
          originalImageHeight
        );
        setGeneratingMask(false);
        return blob;
      } catch (error) {
        console.error("Error loading image:", error);
        setGeneratingMask(false);
        return null;
      }
    }
    return null;
  };

  const handleConfirm = async () => {
    if (dailyInpaintUsed) {
      handleCancelInpainting();
      dispatch(
        addAlertDialog({
          title: "Subscription Required",
          description: "Upgrade to continue using Replace!",
          nextButtonText: "Upgrade",
          type: AlertDialogType.SubscriptionRequired,
        })
      );
      return;
    }
    if (generatingMask) return;
    const mask = await generateMaskImage();
    genDispatch({ type: "SET_IMAGE_MASK", payload: mask });
    if (images.length === 0 && selectedImage) {
      genDispatch({ type: "SET_IMAGES", payload: [selectedImage] });
    }

    if (!mask) {
      genDispatch({ type: "SET_IS_EDITING_MASK", payload: false });
      return;
    }

    dispatch(setSelectedModel(TransformationType.HD));
    const formattedPrompt = prompt.trim();

    let ideaUUID: string | null = null;
    const inspiration = [];
    if (selectedImage) {
      inspiration.push(selectedImage);
    } else if (images.length > 0) {
      inspiration.push(images[0]);
    }

    if (roomUUID && generations.length > 0) {
      ideaUUID = await dispatch(
        getNewGenerations({
          prompt: formattedPrompt,
          inspiration,
          imageMask: mask,
        })
      ).unwrap();
    } else {
      ideaUUID = await dispatch(
        createRoom({
          prompt: formattedPrompt,
          inspiration,
          imageMask: mask,
        })
      ).unwrap();
    }

    genDispatch({ type: "SET_IS_EDITING_MASK", payload: false });

    if (!roomUUID && ideaUUID) {
      router.push(`/idea/${ideaUUID}`);
    }
  };

  if (!selectedImage || !isEditingMask) {
    return null;
  }

  return (
    <Modal open={isEditingMask} onClose={handleCancelInpainting}>
      <div
        className={classes.content}
        style={{ overflowY: isDrawing ? "hidden" : "auto" }}
      >
        <h2>Replace Area</h2>
        <p className={classes.text}>
          Draw over part of the image where you want it to be changed
        </p>
        <InpaintCanvas
          ref={canvasRef}
          image={selectedImage}
          strokes={strokes}
          setStrokes={setStrokes}
          generatingMask={generatingMask}
          isDrawing={isDrawing}
          setIsDrawing={onDrawingChange}
        />
        <p className={classes.text}>
          What do you want to fill / replace the area with?
        </p>
        <TextField
          value={prompt}
          onUpdate={handleUpdatePrompt}
          placeholder="Enter description (optional)"
          className={classes.promptInput}
          size="small"
        />
        <div className={classes.buttonContainer}>
          <Button
            color="secondary"
            onClick={handleConfirm}
            loading={generatingMask}
            disabled={strokes.length === 0}
            className={classes.goButton}
          >
            Go
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default InpaintModal;

import { makeStyles } from "tss-react/mui";
import { keyframes } from "tss-react";

export const useStyles = makeStyles()((theme) => ({
  container: {
    position: "relative",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wiggleAnimation: {
    transform: "translate3d(0, 0, 0)",
    backfaceVisibility: "hidden",
    perspective: "1000px",
    "& *": {
      opacity: "1 !important",
      color: `${theme.palette.secondary.main} !important`,
    },
    animation: `${keyframes`
        10%, 90% {
            transform: translate3d(-1px, 0, 0);
        }
        20%, 80% {
            transform: translate3d(2px, 0, 0);
        }
        30%, 50%, 70% {
            transform: translate3d(-4px, 0, 0);
        }
        40%, 60% {
            transform: translate3d(4px, 0, 0);
        }
        `} 0.82s cubic-bezier(.36,.07,.19,.97) both`,
  },
}));

"use client";

import { useState } from "react";
import IconButton from "@mui/material/IconButton";

import { useStyles } from "./styles";
import Modal from "@/components/common/Modal";
import TutorialPanel from "../TutorialPanel";
import OutlinedIcon from "@/components/icons/QuestionCircleOutlined";
import FilledIcon from "@/components/icons/QuestionCircle";

const TutorialModal = () => {
  const { classes } = useStyles();
  const [showModal, setShowModal] = useState(false);

  const handleToggle = () => {
    setShowModal((prevValue) => !prevValue);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <IconButton
        color="primary"
        onClick={handleToggle}
        aria-label="toggle view tutorials"
        className={classes.buttonContainer}
      >
        <OutlinedIcon className={classes.icon} />
        <FilledIcon className={classes.activeIcon} />
      </IconButton>
      <Modal open={showModal} onClose={handleClose} disableBackdropClose>
        <div className={classes.container}>
          <div className={classes.content}>
            <TutorialPanel />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TutorialModal;

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    position: "fixed",
    bottom: 0,
    right: 0,
    marginLeft: "3px",
    padding: theme.spacing(2, 8),
    backgroundColor: "#ffffff",
    fontSize: "0.8rem",
    color: theme.palette.primary.main,
    boxShadow: "0px -2px 0px #DEE4EB",
    zIndex: 100,
    [theme.breakpoints.up("lg")]: {
      width: "calc(100vw - 340px)",
      flexDirection: "row",
      gap: 0,
      boxShadow: "none",
      padding: theme.spacing(4, 8),
    },
  },
  links: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(4),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontSize: "0.8rem",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.63rem",
    },
  },
  iconLink: {
    display: "grid",
    placeItems: "center",
    fontSize: "1.5rem",
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
  copyright: {
    fontSize: "0.75rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.63rem",
    },
  },
}));

"use client";

import { FC, useState, ReactNode, MouseEvent } from "react";
import { BsAspectRatio, BsAspectRatioFill } from "react-icons/bs";

import { useStyles } from "./styles";
import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import ControlButton from "@/components/idea/GenInputs/Controls/ControlButton";

interface PopoverButtonProps {
  selected: string;
  options: { value: string; icon: ReactNode; label: string }[];
  onSelect: (value: string) => void;
  aspectRatioCount: number;
  disabled?: boolean;
}

const PopoverButton: FC<PopoverButtonProps> = ({
  selected,
  options,
  onSelect,
  aspectRatioCount,
  disabled = false,
}) => {
  const { classes } = useStyles({ aspectRatioCount });
  const [anchorEl, setAnchorEl] = useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);
  const selectedIcon = options.find((option) => option.value === selected);

  const handleClick = (
    event: MouseEvent<HTMLButtonElement | HTMLDivElement>
  ) => {
    if (disabled) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (newValue: string) => () => {
    onSelect(newValue);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div className={classes.toggleButtonContainer} onClick={handleClick}>
        <ControlButton
          label="Aspect Ratio Select"
          icon={<BsAspectRatio />}
          activeIcon={<BsAspectRatioFill />}
          isActive={selectedIcon !== undefined && selectedIcon.value !== "1:1"}
          disabled={disabled}
          tooltip={"Change Aspect Ratio"}
        />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className={classes.popover}
      >
        <div className={classes.options}>
          {options.map((option, index) => (
            <Tooltip
              key={`popover-tooltip-${index + 1}`}
              title={option.label}
              placement="bottom"
              arrow
            >
              <div
                aria-label={option.label}
                onClick={handleSelect(option.value)}
                className={classes.iconButton}
              >
                {option.icon}
              </div>
            </Tooltip>
          ))}
        </div>
      </Popover>
    </>
  );
};

export default PopoverButton;

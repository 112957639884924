import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<{ aspectRatioCount: number }>()(
  (theme, { aspectRatioCount }) => ({
    toggleButtonContainer: {
      display: "grid",
      placeItems: "center",
    },
    iconButton: {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
    popover: {
      zIndex: 1600,
    },
    options: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
      minWidth: aspectRatioCount * 50,
      minHeight: 80,
      [theme.breakpoints.down("md")]: {
        minWidth: 0,
        flexWrap: "wrap",
        justifyContent: "flex-start",
      },
    },
    selected: {
      color: theme.palette.secondary.main,
    },
  })
);

import { FC } from "react";

import { useStyles } from "./styles";

interface AspectRatioIconProps {
  w: number;
  h: number;
  active?: boolean;
  disabled?: boolean;
}

const AspectRatioIcon: FC<AspectRatioIconProps> = ({
  w,
  h,
  active = false,
  disabled = false,
}) => {
  const { classes, cx } = useStyles();

  const aspectRatio = w / h;

  // Calculate dimensions based on the aspect ratio, ensuring the max dimension is 30px
  let svgWidth, svgHeight;
  if (aspectRatio >= 1) {
    svgWidth = 30;
    svgHeight = svgWidth / aspectRatio;
  } else if (aspectRatio === 1) {
    svgWidth = 30;
    svgHeight = 30;
  } else {
    svgHeight = 30;
    svgWidth = svgHeight * aspectRatio;
  }

  return (
    <svg
      width={`${svgWidth}px`}
      height={`${svgHeight}px`}
      viewBox={`0 0 ${svgWidth} ${svgHeight}`}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      className={cx(
        classes.icon,
        active && classes.active,
        disabled && classes.disabled
      )}
    >
      <rect
        width="100%"
        height="100%"
        fill="none"
        stroke="currentColor"
        strokeWidth={1}
      />
      <text
        x="50%"
        y="50%"
        alignmentBaseline="middle"
        textAnchor="middle"
        fontFamily="Arial"
        fontSize="8px"
        fill="currentColor"
      >
        {w}:{h}
      </text>
    </svg>
  );
};

export default AspectRatioIcon;

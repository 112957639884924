"use client";

import { FC } from "react";
import { useRouter } from "next/navigation";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import Divider from "@mui/material/Divider";
import ProfileFilled from "@mui/icons-material/AccountCircle";
import ProfileOutlined from "@mui/icons-material/AccountCircleOutlined";
import SupportFilled from "@mui/icons-material/Phone";
import SupportOutlined from "@mui/icons-material/PhoneOutlined";
import TermsFilled from "@mui/icons-material/Description";
import TermsOutlined from "@mui/icons-material/DescriptionOutlined";
import PrivacyFilled from "@mui/icons-material/PrivacyTip";
import PrivacyOutlined from "@mui/icons-material/PrivacyTipOutlined";
import LogoutFilled from "@mui/icons-material/Logout";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";

import { useStyles } from "./styles";
import { User } from "@/types/user";
import DropdownMenuItem from "./DropdownMenuItem";
import SettingsFilled from "@/components/icons/Settings";
import SettingsOutlined from "@/components/icons/SettingsOutlined";

interface DropdownMenuProps {
  user: User;
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const DropdownMenu: FC<DropdownMenuProps> = ({
  user,
  open,
  anchorEl,
  onClose,
}) => {
  const { classes } = useStyles();
  const router = useRouter();
  const options: any[] = [
    {
      label: "View Profile",
      icon: <ProfileOutlined />,
      activeIcon: <ProfileFilled />,
    },
    {
      label: "Settings",
      icon: <SettingsOutlined />,
      activeIcon: <SettingsFilled />,
    },
    {
      label: "Contact Support",
      icon: <SupportOutlined />,
      activeIcon: <SupportFilled />,
    },
    {
      label: "Terms of Service",
      icon: <TermsOutlined />,
      activeIcon: <TermsFilled />,
    },
    {
      label: "Privacy Policy",
      icon: <PrivacyOutlined />,
      activeIcon: <PrivacyFilled />,
    },
  ];

  const handleMenuSelect = (option: string) => {
    onClose();
    switch (option) {
      case "View Profile":
        router.push(`/profile/${user.username}`);
        break;
      case "Settings":
        router.push("/settings");
        break;
      case "Contact Support":
        if (typeof window !== "undefined") {
          const subject = "Support Request";
          const body = "Please describe your issue below:";
          const encodedSubject = encodeURIComponent(subject);
          const encodedBody = encodeURIComponent(body);
          window.location.href = `mailto:support@idyllic.app?subject=${encodedSubject}&body=${encodedBody}`;
        }
        break;
      case "Terms of Service":
        if (typeof window !== "undefined") {
          window.open("https://idyllic.app/terms-conditions/", "_blank");
        }
        break;
      case "Privacy Policy":
        if (typeof window !== "undefined") {
          window.open("https://idyllic.app/privacy-policy/", "_blank");
        }
        break;
      case "Logout":
        router.push("/logout");
        break;
      default:
        break;
    }
  };

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      onClose={onClose}
      className={classes.menu}
    >
      <div className={classes.content}>
        <MenuList>
          {options.map((option) => (
            <DropdownMenuItem
              key={`menu-item-${option.label}`}
              name={option.label}
              icon={option.icon}
              activeIcon={option.activeIcon}
              onSelect={handleMenuSelect}
            />
          ))}
          <Divider />
          <DropdownMenuItem
            name="Logout"
            icon={<LogoutOutlined />}
            activeIcon={<LogoutFilled />}
            onSelect={handleMenuSelect}
          />
        </MenuList>
      </div>
    </Menu>
  );
};

export default DropdownMenu;

"use client";

import { FC, ChangeEvent, KeyboardEvent, ClipboardEvent, useRef } from "react";

import { useStyles } from "./styles";
import { AlertDialogType } from "@/types/alert";
import { useAppDispatch } from "@/redux/store";
import { addAlertDialog } from "@/redux/actions";
import { useGenContext } from "@/context/GenContext";
import ImagePreviews from "./ImagePreviews";
import TypingSpinner from "@/components/common/TypingSpinner";
import SubmitIcon from "@/components/icons/Send";

interface PromptInputProps {
  prompt: string;
  setPrompt: (prompt: string) => void;
  onSubmit: () => void;
  loading: boolean;
  error?: string;
  onFocus?: () => void;
  placeholder?: string;
}

const PromptInput: FC<PromptInputProps> = ({
  prompt,
  setPrompt,
  onSubmit,
  loading,
  error,
  onFocus,
  placeholder,
}) => {
  const dispatch = useAppDispatch();
  const { state, dispatch: genDispatch } = useGenContext();
  const { images } = state;
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const { classes } = useStyles({
    disabled: loading,
  });

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSubmit();
    }
  };

  const handlePromptChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setPrompt(event.target.value);

    // Handles the textarea auto-resize
    if (inputRef?.current) {
      inputRef.current.style.height = "1px";
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  };

  const handlePaste = (event: ClipboardEvent<HTMLTextAreaElement>) => {
    const clipboardData = event.clipboardData;
    const items = clipboardData.items;

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        const blob = items[i].getAsFile();
        const reader = new FileReader();
        if (!blob) return;

        // Only allow webp, png, jpeg, jpg
        if (
          !items[i].type.includes("image/png") &&
          !items[i].type.includes("image/jpeg") &&
          !items[i].type.includes("image/jpg") &&
          !items[i].type.includes("image/webp")
        ) {
          dispatch(
            addAlertDialog({
              title: "Invalid Image Type",
              description:
                "Please only paste images of type PNG, JPEG, or WEBP",
              prevButtonText: "Ok",
              singleOption: true,
              type: AlertDialogType.Default,
            })
          );
          return;
        }

        reader.onload = (e) => {
          if (e.target && e.target.result) {
            const newImage = e.target.result as string;
            if (images.length >= 9) {
              dispatch(
                addAlertDialog({
                  title: "Image Limit Reached",
                  description: "You can only select 9 images per generation",
                  prevButtonText: "Ok",
                  singleOption: true,
                  type: AlertDialogType.Default,
                })
              );
              return;
            }

            genDispatch({ type: "SET_IMAGES", payload: [...images, newImage] });
            genDispatch({ type: "SET_STROKES", payload: [] });
            genDispatch({ type: "SET_IMAGE_MASK", payload: null });
          }
        };
        reader.readAsDataURL(blob);
        event.preventDefault();
        break;
      }
    }
  };

  return (
    <div>
      <div className={classes.container}>
        <ImagePreviews />
        <div className={classes.textAreaContainer}>
          <textarea
            value={prompt}
            onChange={handlePromptChange}
            className={classes.textArea}
            placeholder={placeholder || "What do you want to make?"}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            ref={inputRef}
            onFocus={onFocus}
          />
          <div className={classes.inputAdornment}>
            {loading ? <TypingSpinner /> : <SubmitIcon onClick={onSubmit} />}
          </div>
        </div>
      </div>
      <p className={classes.errorMessage}>{error}</p>
    </div>
  );
};

export default PromptInput;

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  container: {
    display: "inline-block",
    position: "relative",
  },
  cursor: {
    position: "absolute",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    border: "2px solid pink",
    pointerEvents: "none",
    zIndex: 10,
    transform: "translate(-50%, -50%)",
  },
  undoButton: {
    fontSize: "2rem",
    position: "absolute",
    top: theme.spacing(4),
    right: theme.spacing(4),
    cursor: "pointer",
    color: "#ffffff",
    filter: `drop-shadow(0px 0px 1px ${theme.palette.primary.main})`,
    transition: "background-color 0.1s, transform 0.1s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
}));

"use client";

import { useEffect } from "react";
import { useRouter } from "next/navigation";

import { useAppSelector, useAppDispatch } from "@/redux/store";
import {
  checkRoomJobStatus,
  redeemTrialCoupon,
  getQuotas,
} from "@/redux/actions";
import useInterval from "@/hooks/useInterval";

const LayoutScripts = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const user = useAppSelector((state) => state.user.user);
  const authLoading = useAppSelector((state) => state.user.authLoading);
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
  const promoCode = useAppSelector((state) => state.user.promoCode);
  // const [metaAds] = useSessionStorage("MetaAds", false);

  const pollJobStatus = () => {
    if (user && user.activeJobs.length > 0) {
      const jobId = user.activeJobs[0].id;
      dispatch(checkRoomJobStatus(jobId));
    }
  };

  useInterval(
    pollJobStatus,
    user && user.activeJobs.length > 0 ? 3000 : null // Poll every 3 seconds if there's an active job
  );

  useEffect(() => {
    // Initial dispatch when the component mounts or when user state changes
    if (user && user.activeJobs.length > 0) {
      pollJobStatus();
    }

    if (user) {
      dispatch(getQuotas());
    }
  }, [user]);

  useEffect(() => {
    if (promoCode) {
      if (!isAuthenticated && !authLoading) {
        router.push("/register");
      } else {
        dispatch(redeemTrialCoupon(promoCode));
      }
    }
  }, [promoCode, isAuthenticated]);

  // useEffect(() => {
  //   // Check for MetaAds in session storage and emit event if present
  //   if (metaAds === true && user?.onboardingComplete === true) {
  //     window.fbq("track", "SignUp");
  //   }
  // }, [user?.onboardingComplete]);

  return null;
};

export default LayoutScripts;

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.63rem",
    },
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(12),
  },
  submitButton: {
    width: 150,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.action.disabled,
    },
  },
  textField: {
    maxWidth: 450,
  },
  modal: {
    maxWidth: 600,
  },
}));

import { FC } from "react";
import dynamic from "next/dynamic";

const Desktop = dynamic(() => import("./Desktop"));
const Mobile = dynamic(() => import("./Mobile"));

interface SidebarProps {
  isMobile?: boolean;
}

const Sidebar: FC<SidebarProps> = ({ isMobile }) => {
  return isMobile ? <Mobile /> : <Desktop />;
};

export default Sidebar;

import { useState } from "react";

import { useStyles } from "./styles";
import { useAppDispatch } from "@/redux/store";
import { sendFeatureRequest } from "@/redux/actions";
import Modal from "@/components/common/Modal";
import TextField from "@/components/common/TextField";
import PrimaryButton from "@/components/common/PrimaryButton";

const RequestFeatureButton = () => {
  const dispatch = useAppDispatch();
  const [text, setText] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const { classes } = useStyles();

  const handleOpenRequestFeature = () => {
    setOpenModal(true);
  };
  const handleCloseRequestFeature = () => {
    setOpenModal(false);
  };

  const handleRequestFeature = () => {
    setOpenModal(false);
    if (process.env.NODE_ENV !== "production") return;
    dispatch(sendFeatureRequest({ text }));
  };

  return (
    <>
      <p className={classes.link} onClick={handleOpenRequestFeature}>
        Request a Feature
      </p>
      <Modal
        heading="Request a feature"
        open={openModal}
        onClose={handleCloseRequestFeature}
        className={classes.modal}
      >
        <div className={classes.modalContainer}>
          <p>
            Thanks for using Idyllic. We&apos;re rapidly improving the app, help
            us shape the future of Idyllic with your feature request.
          </p>

          <TextField
            multiline
            className={classes.textField}
            value={text}
            onUpdate={setText}
            placeholder="What feature would you like to request?"
          />
          <PrimaryButton
            color="secondary"
            className={classes.submitButton}
            onClick={handleRequestFeature}
            disabled={text.trim().length === 0}
          >
            Submit
          </PrimaryButton>
        </div>
      </Modal>
    </>
  );
};

export default RequestFeatureButton;

"use client";

import { useState, useRef, useCallback } from "react";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import useExploreSearch from "@/hooks/useExploreSearch";
import Skeleton from "@mui/material/Skeleton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";

import { useStyles } from "./styles";
import { useAppDispatch } from "@/redux/store";
import { setRoomUUID, setSelectedGeneration } from "@/redux/actions";
import Modal from "@/components/common/Modal";
import Search from "@/components/layout/Search";
import Masonry, { ResponsiveMasonry } from "@/components/layout/masonry";
import FeedCard from "@/components/feed/FeedCard";

const SearchModal = () => {
  const { classes } = useStyles();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const dispatch = useAppDispatch();
  const [prompt, setPrompt] = useState("");
  const [open, setOpen] = useState(false);
  const { searchResults, loading } = useExploreSearch(prompt, open, 1000);
  const displayIncrement = isMobile ? 10 : 30;
  const [displayCount, setDisplayCount] = useState(displayIncrement);
  const displayedImages = searchResults.slice(0, displayCount);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading || searchResults.length === 0) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (searchResults.length > displayCount) {
            setDisplayCount((prev) => prev + displayIncrement);
          }
        }
      });
      if (node) observer.current.observe(node);
    },

    [loading, searchResults, displayCount]
  );

  const onToggleOpen = () => {
    setOpen((prevState) => !prevState);
  };

  const handleClearSearch = () => {
    setPrompt("");
  };

  const onClose = () => {
    setOpen(false);
    handleClearSearch();
  };

  const handleSearch = async (prompt: string) => {
    setPrompt(prompt);
  };

  const handleImageClick = (id?: number) => {
    onClose();
    dispatch(setRoomUUID(null));
    if (id) {
      dispatch(setSelectedGeneration(id));
    }
  };

  const placeholder = Array.from({ length: isMobile ? 8 : 30 }).map(
    (_, index) => (
      <Skeleton
        key={`placeholder-${index + 1}`}
        variant="rectangular"
        width="100%"
        height={150}
        className={classes.placeholderCard}
      />
    )
  );

  return (
    <>
      <IconButton onClick={onToggleOpen} aria-label="toggle open search">
        <SearchIcon color="primary" className={classes.icon} />
      </IconButton>
      <Modal
        open={open}
        onClose={onClose}
        heading="Explore ideas"
        className={classes.modal}
        positionOverrides={classes.modalPosition}
      >
        <Search
          onSearch={handleSearch}
          onClearSearch={handleClearSearch}
          onPromptChange={setPrompt}
          onClose={onClose}
          loading={loading}
        />
        <div className={classes.resultsContainer}>
          <ResponsiveMasonry
            columnsCountBreakpoints={{
              350: 2,
              800: 3,
              1200: 4,
              1600: 5,
            }}
          >
            <Masonry gutter="0.5em">
              {displayedImages.map((item, index) => (
                <div
                  key={`search-item-${index + 1}`}
                  onClick={() => handleImageClick(item.meta?.generationId)}
                  ref={
                    displayedImages.length === index + 1 ? lastElementRef : null
                  }
                >
                  <div
                    data-track-resource=""
                    data-resource-id={
                      item.type === "image" ? item.meta.imageId : ""
                    }
                    data-resource-name="image"
                  >
                    <FeedCard item={item} />
                  </div>
                </div>
              ))}
              {loading && placeholder}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </Modal>
    </>
  );
};

export default SearchModal;

"use client";

import { FC } from "react";
import FilledIcon from "@mui/icons-material/AddPhotoAlternate";
import OutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";

import { AlertDialogType } from "@/types/alert";
import { useAppDispatch } from "@/redux/store";
import { addAlertDialog } from "@/redux/actions";
import { useGenContext } from "@/context/GenContext";
import ControlButton from "../ControlButton";

const MAX_FILE_SIZE = 10; // max file size in MB
const MAX_IMAGES = 9;

interface ImageSelectorProps {
  disableEdits?: boolean;
}

const ImageSelector: FC<ImageSelectorProps> = ({ disableEdits = false }) => {
  const dispatch = useAppDispatch();
  const { state, dispatch: genDispatch } = useGenContext();
  const { images } = state;
  const disableSelect = disableEdits || images.length >= MAX_IMAGES;
  const maxImages =
    images.length >= MAX_IMAGES ? 0 : MAX_IMAGES - images.length;

  const handleImageEdit = () => {
    if (images.length >= 9) {
      dispatch(
        addAlertDialog({
          title: "Image Limit Reached",
          description: `You can only select ${MAX_IMAGES} images per generation`,
          prevButtonText: "Ok",
          singleOption: true,
          type: AlertDialogType.Default,
        })
      );
      return;
    }

    if (disableEdits) {
      dispatch(
        addAlertDialog({
          title: "Subscription Required",
          description:
            "You have used all your daily edits. Upgrade to Premium or Professional to get unlimited edits!",
          nextButtonText: "Upgrade",
          type: AlertDialogType.SubscriptionRequired,
        })
      );
      return;
    }

    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/png,image/jpeg,image/jpg,image/webp";
    input.multiple = true;
    input.style.display = "none";
    input.onchange = (event) => {
      const target = event.target as HTMLInputElement;
      if (target.files) {
        if (target.files.length > maxImages) {
          dispatch(
            addAlertDialog({
              title: "Image Limit Reached",
              description: `You can only select ${MAX_IMAGES} images per generation`,
              prevButtonText: "Ok",
              singleOption: true,
              type: AlertDialogType.Default,
            })
          );
          return;
        }

        const newFiles = Array.from(target.files);

        for (const file of newFiles) {
          if (file.size > MAX_FILE_SIZE * 1024 * 1024) {
            alert(`File too large. Max file size is ${MAX_FILE_SIZE}MB`);
            return;
          }
        }

        const newBlobs = newFiles.map(
          (file) => new Blob([file], { type: file.type })
        );

        genDispatch({
          type: "SET_IMAGES",
          payload: [...images, ...newBlobs],
        });
      }
      input.remove();
    };

    document.body.appendChild(input);
    input.click();
  };

  return (
    <ControlButton
      label="Upload Image"
      icon={<OutlinedIcon />}
      activeIcon={<FilledIcon />}
      disabled={disableSelect}
      onClick={handleImageEdit}
      isActive={images.length > 0}
      tooltip="Add Image(s)"
    />
  );
};

export default ImageSelector;

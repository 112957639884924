import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<{ hasImages: boolean }>()(
  (theme, { hasImages }) => ({
    container: {
      display: "flex",
      gap: theme.spacing(2),
      alignItems: "flex-end",
      marginBottom: hasImages ? theme.spacing(4) : 0,
      paddingTop: hasImages ? theme.spacing(2) : 0,
      overflowX: "auto",
      "&::-webkit-scrollbar": {
        width: 0,
      },
    },
  })
);

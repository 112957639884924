"use client";

import { FC, MouseEvent } from "react";
import { usePathname } from "next/navigation";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";
import { FaShareAlt } from "react-icons/fa";
import { AiOutlineLink } from "react-icons/ai";

import { useStyles } from "./styles";
import { DiscordShareHookType } from "@/types/social";
import { useAppDispatch } from "@/redux/store";
import { generateAlert, sendShareInteraction } from "@/redux/actions";

interface ShareButtonProps {
  url?: string;
  disabled?: boolean;
  color?: string;
  icon?: "link" | "share";
  shadow?: boolean;
  variant?: DiscordShareHookType;
  tooltip?: string;
  className?: string;
}

const ShareButton: FC<ShareButtonProps> = ({
  url,
  disabled = false,
  color,
  icon = "link",
  shadow = false,
  variant = "gen",
  tooltip = "Copy Link",
  className = "",
}) => {
  const { classes, cx } = useStyles();
  const dispatch = useAppDispatch();
  const pathname = usePathname() as string;
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("md")
  );

  const postToDiscord = (url: string, variant: DiscordShareHookType) => {
    if (process.env.NODE_ENV === "development") return;
    dispatch(sendShareInteraction({ url, currentPath: pathname, variant }));
  };

  const handleShare = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    let sharedUrl = url;
    if (!sharedUrl && typeof window !== "undefined") {
      sharedUrl = window.location.href;
    }
    if (!sharedUrl) return;
    navigator.clipboard.writeText(sharedUrl);
    dispatch(
      generateAlert({ text: "Link copied to clipboard", type: "success" })
    );
    postToDiscord(sharedUrl, variant);
  };

  return (
    <div className={classes.iconContainer}>
      <Tooltip title={tooltip} arrow>
        <IconButton
          sx={{
            color: color || "primary.main",
            filter: shadow ? "drop-shadow(0px 0px 1px #000000)" : "none",
          }}
          onClick={handleShare}
          disabled={disabled}
          aria-label="Share"
          className={cx(classes.icon, className)}
          size={isMobile ? "small" : "medium"}
        >
          {icon === "share" ? <FaShareAlt /> : <AiOutlineLink />}
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ShareButton;

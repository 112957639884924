import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  chatBubble: {
    position: "absolute",
    bottom: "-80px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    borderRadius: "12px",
    fontSize: "14px",
    whiteSpace: "nowrap",
    zIndex: 1000,
    "&::after": {
      content: '""',
      position: "absolute",
      top: "-20px",
      left: "50%",
      transform: "translateX(-50%)",
      borderWidth: "12px",
      borderStyle: "solid",
      borderColor: `transparent transparent ${theme.palette.primary.main} transparent`,
    },
    [theme.breakpoints.down("lg")]: {
      left: 150,
    },
  },
  chatBubbleInner: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    padding: theme.spacing(3),
  },
  chatBubbleText: {
    fontWeight: 500,
  },
  closeButton: {
    position: "absolute",
    top: "0",
    right: "0",
    transform: "translate(50%, -50%)",
    padding: theme.spacing(1),
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
}));

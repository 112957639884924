"use client";

import { ReactNode, forwardRef, ForwardedRef } from "react";
import SearchIcon from "@mui/icons-material/Search";

import { useStyles } from "./styles";

interface ListItemProps {
  value: string;
  isSelected: boolean;
  onSelect: (value?: string) => void;
  onTouchStart?: (e: any) => void;
  onTouchMove?: (e: any) => void;
  onTouchEnd?: (value: string) => void;
  icon?: ReactNode;
  className?: string;
  ref?: ForwardedRef<HTMLDivElement>;
}

const ListItem = forwardRef<HTMLDivElement, ListItemProps>(
  (
    {
      value,
      isSelected,
      onSelect,
      onTouchStart,
      onTouchMove,
      onTouchEnd,
      icon = <SearchIcon />,
      className,
    },
    ref
  ) => {
    const { classes, cx } = useStyles();

    const handleSelect = () => {
      onSelect(value);
    };

    return (
      <div
        className={cx(
          classes.container,
          className,
          isSelected && classes.selected
        )}
        onMouseDown={handleSelect}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={() => onTouchEnd && onTouchEnd(value)}
        ref={ref}
      >
        {icon}
        <span>{value}</span>
      </div>
    );
  }
);

export default ListItem;

ListItem.displayName = "ListItem";

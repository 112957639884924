"use client";

import { FC, useState } from "react";
import { useRouter } from "next/navigation";

import { ViewMode } from "@/types/thread";
import { AlertDialogType } from "@/types/alert";
import { useAppSelector, useAppDispatch } from "@/redux/store";
import {
  setViewMode,
  updateGenerationViewMode,
  addAlertDialog,
} from "@/redux/actions";
import ControlButton from "@/components/idea/GenInputs/Controls/ControlButton";
import PublicIcon from "@/components/icons/Public";
import PrivateIcon from "@/components/icons/PublicOff";

interface ViewModeToggleProps {
  generationViewMode?: ViewMode;
  generationId?: number;
  scale?: number;
}

const ViewModeToggle: FC<ViewModeToggleProps> = ({
  generationViewMode,
  generationId,
  scale = 1,
}) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const user = useAppSelector((state) => state.user.user);
  const isSubscribed = Boolean(user && user?.subscriptionType !== "free");
  const viewMode = useAppSelector((state) => state.room.viewMode);
  const [displayedViewMode, setDisplayedViewMode] = useState<ViewMode>(
    generationViewMode || viewMode
  );
  const isDisabled = displayedViewMode === ViewMode.Restricted;

  const handleToggleViewMode = async () => {
    if (displayedViewMode) {
      if (isDisabled) {
        return;
      }

      if (!isSubscribed) {
        dispatch(
          addAlertDialog({
            title: "Subscription Required",
            description: "Upgrade to make your creations private!",
            nextButtonText: "Upgrade",
            type: AlertDialogType.SubscriptionRequired,
          })
        );
        return;
      }

      const existingViewMode = displayedViewMode;
      const newViewMode =
        displayedViewMode === ViewMode.Public
          ? ViewMode.Private
          : ViewMode.Public;

      setDisplayedViewMode(newViewMode);

      if (generationId) {
        const updateSuccessful = await dispatch(
          updateGenerationViewMode({
            generationId: generationId,
            viewMode: newViewMode,
          })
        ).unwrap();
        if (!updateSuccessful) {
          setDisplayedViewMode(existingViewMode);
        }
      } else {
        dispatch(setViewMode(newViewMode));
      }
    }
  };

  if (displayedViewMode === ViewMode.Restricted) {
    return null;
  }

  return (
    <ControlButton
      label="HD Toggle"
      icon={<PublicIcon />}
      activeIcon={<PrivateIcon />}
      disabled={!isSubscribed}
      onClick={handleToggleViewMode}
      isActive={displayedViewMode === ViewMode.Private}
      tooltip={
        displayedViewMode === ViewMode.Public ? "Make Private" : "Make Public"
      }
      scale={scale}
    />
  );
};

export default ViewModeToggle;

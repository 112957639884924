"use client";

import {
  FC,
  useState,
  ChangeEventHandler,
  HTMLInputTypeAttribute,
  KeyboardEvent,
} from "react";
import MUI_TextField, { OutlinedTextFieldProps } from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useStyles } from "./styles";
import TypingSpinner from "../TypingSpinner";
import CloseIcon from "@/components/icons/Close";
import SubmitIcon from "@/components/icons/Send";

interface TextFieldProps extends Omit<OutlinedTextFieldProps, "variant"> {
  onUpdate: (value: string) => void;
  type?: HTMLInputTypeAttribute;
  minRows?: number;
  errorMessage?: string;
  onSubmit?: (event?: any) => void;
  loading?: boolean;
  endAdornment?: JSX.Element;
  clearable?: boolean;
}

const TextField: FC<TextFieldProps> = ({
  onUpdate,
  type = "text",
  errorMessage = "",
  onSubmit,
  loading,
  endAdornment,
  clearable,
  ...muiProps
}) => {
  const { classes, cx } = useStyles({ disabled: Boolean(errorMessage) });
  const [showPassword, setShowPassword] = useState(false);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onUpdate(event.target.value);
  };

  const handleToggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && onSubmit) {
      e.preventDefault();
      onSubmit();
    }
  };

  const handleClearInput = () => {
    onUpdate("");
  };

  let displayType = type;
  if (type === "password" && showPassword) {
    displayType = "text";
  }

  let inputAdornment = null;
  if (type === "password") {
    inputAdornment = (
      <InputAdornment
        position="end"
        className={classes.inputAdornment}
        onClick={handleToggleShowPassword}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </InputAdornment>
    );
  }

  if (endAdornment) {
    inputAdornment = (
      <InputAdornment position="end" className={classes.inputAdornment}>
        {endAdornment}
      </InputAdornment>
    );
  }

  if (!loading && muiProps.value && !onSubmit && clearable) {
    inputAdornment = (
      <InputAdornment
        position="end"
        className={classes.inputAdornment}
        onClick={handleClearInput}
      >
        <CloseIcon />
      </InputAdornment>
    );
  }

  if (loading && !onSubmit) {
    inputAdornment = (
      <InputAdornment position="end" className={classes.inputAdornment}>
        <TypingSpinner />
      </InputAdornment>
    );
  }

  if (onSubmit) {
    inputAdornment = (
      <InputAdornment
        position="end"
        className={classes.inputAdornment}
        onClick={onSubmit}
      >
        {loading ? <TypingSpinner /> : <SubmitIcon />}
      </InputAdornment>
    );
  }

  return (
    <MUI_TextField
      {...muiProps}
      variant="outlined"
      type={displayType}
      onChange={handleChange}
      fullWidth
      error={Boolean(errorMessage)}
      helperText={errorMessage || muiProps.helperText}
      className={cx(classes.textField, muiProps.className)}
      InputProps={{
        endAdornment: inputAdornment,
      }}
      onKeyDown={handleKeyDown}
    />
  );
};

export default TextField;

"use client";

import { FC, MouseEvent } from "react";
import Tooltip from "@mui/material/Tooltip";

import { useStyles } from "./styles";

interface ControlButtonProps {
  icon: JSX.Element;
  activeIcon: JSX.Element;
  isActive: boolean;
  label: string;
  disabled?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  tooltip?: string;
  scale?: number;
  className?: string;
  id?: string;
}

const ControlButton: FC<ControlButtonProps> = ({
  icon,
  activeIcon,
  isActive,
  label,
  onClick,
  disabled = false,
  tooltip,
  scale = 1,
  className,
  id,
}) => {
  const { classes, cx } = useStyles({ disabled, isActive, scale });

  return (
    <Tooltip title={tooltip} arrow placement="top">
      <button
        onClick={onClick}
        className={cx(classes.container, className)}
        id={id}
        aria-label={label}
      >
        <div className={cx(classes.icon, disabled && classes.disabled)}>
          {icon}
        </div>
        <div className={cx(classes.activeIcon, disabled && classes.disabled)}>
          {activeIcon}
        </div>
      </button>
    </Tooltip>
  );
};

export default ControlButton;

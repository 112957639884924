"use client";

import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";

import { useStyles } from "./styles";
import { useAppDispatch } from "@/redux/store";
import { requestGeneration } from "@/redux/actions";
import Modal from "@/components/common/Modal";
import TextField from "@/components/common/TextField";
import PrimaryButton from "@/components/common/PrimaryButton";

const GenerationRequest = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const [text, setText] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const handleOpenGenerationRequest = () => {
    setOpenModal(true);
  };
  const handleCloseGenerationRequest = () => {
    setOpenModal(false);
  };

  const handleGenerationRequest = () => {
    setOpenModal(false);
    if (process.env.NODE_ENV !== "production") return;
    dispatch(requestGeneration({ text }));
  };

  return (
    <>
      <Tooltip title="Get an expert to do.." arrow placement="top">
        <div
          className={classes.requestHelpButton}
          onClick={handleOpenGenerationRequest}
        >
          <EmojiObjectsIcon /> <p>Request Help</p>
        </div>
      </Tooltip>
      <Modal
        heading="Request Image Generation by an Idyllic Team Member"
        open={openModal}
        onClose={handleCloseGenerationRequest}
        className={classes.modal}
      >
        <div className={classes.modalContainer}>
          <p>
            If you&apos;re not getting the desired results, our team is here to
            help. Please provide detailed instructions below on what image you
            would like us to create and we will email you the final result.
          </p>
          <TextField
            multiline
            className={classes.textField}
            value={text}
            onUpdate={setText}
            placeholder="Describe what you want to generate..."
          />
          <PrimaryButton
            color="secondary"
            className={classes.submitButton}
            onClick={handleGenerationRequest}
            disabled={text.trim().length === 0}
          >
            Submit
          </PrimaryButton>
        </div>
      </Modal>
    </>
  );
};

export default GenerationRequest;

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    gap: theme.spacing(4),
  },
  userDetails: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: theme.spacing(1),
  },
  username: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "block",
      textAlign: "right",
      fontSize: "0.88rem",
      fontWeight: 500,
    },
  },
  subscriptionDetails: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(5),
  },
  upgradeButton: {
    width: 80,
    height: 20,
    borderRadius: 5,
    fontSize: "0.63rem",
    [theme.breakpoints.up("lg")]: {
      width: 107,
      height: 24,
      fontSize: "0.88rem",
    },
  },
  subscriptionLabel: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "block",
      fontSize: "0.75rem",
      fontWeight: 300,
      fontStyle: "italic",
      textTransform: "uppercase",
    },
  },
  avatar: {
    width: 30,
    height: 30,
    cursor: "pointer",
    [theme.breakpoints.up("lg")]: {
      width: 55,
      height: 55,
    },
  },
}));

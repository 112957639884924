"use client";

import { FC } from "react";
import Typography from "@mui/material/Typography";

import { useStyles } from "./styles";
import TutorialCard from "./TutorialCard";
import GettingStartedThumbnail from "@/assets/thumbnails/getting_started.webp";
import ThreadsThumbnail from "@/assets/thumbnails/threads.webp";
import ImageRemixThumbnail from "@/assets/thumbnails/image_remix.webp";

const TUTORIALS = [
  {
    label: "First Idea",
    image: GettingStartedThumbnail.src,
  },
  {
    label: "Threads",
    image: ThreadsThumbnail.src,
  },
  {
    label: "Image Remix",
    image: ImageRemixThumbnail.src,
  },
  {
    label: "Logos",
    image: "https://files.idyllic.app/files/static/211466",
  },
  {
    label: "Profile Pics",
    image: "https://files.idyllic.app/files/static/235959",
  },
  {
    label: "Anime",
    image: "https://files.idyllic.app/files/static/236385",
  },
  {
    label: "Art",
    image: "https://files.idyllic.app/files/static/108567",
  },
  {
    label: "Tattoos",
    image: "https://files.idyllic.app/files/static/228134",
  },
];

interface TutorialMenuProps {
  selectTutorial: (newSelection: string) => void;
  heading?: string;
  description?: string;
}

const TutorialMenu: FC<TutorialMenuProps> = ({
  selectTutorial,
  heading,
  description,
}) => {
  const { classes } = useStyles();

  const displayedTutorials =
    heading === "Explore" ? [...TUTORIALS].slice(3) : [...TUTORIALS];

  return (
    <div className={classes.container}>
      <Typography variant="h2">{heading}</Typography>
      {description && (
        <Typography variant="body1" className={classes.description}>
          {description}
        </Typography>
      )}
      <div className={classes.cards}>
        {displayedTutorials.map((tutorial) => (
          <TutorialCard
            key={tutorial.label}
            label={tutorial.label}
            image={tutorial.image}
            onClick={() => selectTutorial(tutorial.label)}
          />
        ))}
      </div>
      <Typography
        variant="body1"
        color="secondary"
        className={classes.footerText}
      >
        What will you create?
      </Typography>
    </div>
  );
};

export default TutorialMenu;

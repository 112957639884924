import { FC } from "react";

import IdyllicLogo from "@/assets/logos/logo.svg";
import IdyllicLogoWhite from "@/assets/logos/logo-white.svg";
import IdyllicIcon from "@/assets/logos/icon.svg";

const getLogo = (variant: "logo" | "icon", mode: "light" | "dark") => {
  if (variant === "icon") {
    return IdyllicIcon.src;
  }
  if (mode === "dark") {
    return IdyllicLogoWhite.src;
  }
  return IdyllicLogo.src;
};

interface LogoProps {
  width?: string | number;
  onClick?: () => void;
  variant?: "logo" | "icon";
  mode?: "light" | "dark";
}

const Logo: FC<LogoProps> = ({
  width = "100px",
  onClick,
  variant = "logo",
  mode = "light",
}) => {
  return (
    <img
      src={getLogo(variant, mode)}
      alt="Idyllic Logo"
      style={{ width, height: "auto", objectFit: "contain", cursor: "pointer" }}
      onClick={onClick}
    />
  );
};

export default Logo;

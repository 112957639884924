import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(4),
    [theme.breakpoints.up("lg")]: {
      marginBottom: theme.spacing(2),
    },
  },
  socialButton: {
    color: theme.palette.primary.main,
  },
}));

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<void, "deleteImageButton">()(
  (theme, _params, classes) => ({
    imagePreview: {
      display: "inline-block",
      position: "relative",
      cursor: "pointer",
      "&:hover": {
        [`& .${classes.deleteImageButton}`]: {
          display: "grid",
        },
      },
    },
    image: {
      width: 60,
      height: 60,
      objectFit: "cover",
      borderRadius: 8,
    },
    deleteImageButton: {
      display: "none",
      placeItems: "center",
      position: "absolute",
      top: "-0.25em",
      right: "-0.25em",
      backgroundColor: "#ffffff",
      padding: theme.spacing(1),
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: "50%",
      "&:hover": {
        color: theme.palette.secondary.main,
      },
    },
  })
);

"use client";

import Typography from "@mui/material/Typography";

import { useStyles } from "./styles";
import { useAppSelector } from "@/redux/store";
import { useLocalStorage } from "@/hooks/useStorage";
import Button from "@/components/common/PrimaryButton";
import CloseIcon from "@/components/icons/Close";

async function logInteraction() {
  try {
    await fetch(`${process.env.NEXT_PUBLIC_API_URL}/stats/open-feedback-form`, {
      method: "POST",
      credentials: "include",
    });
  } catch (error: unknown) {
    let errorMessage = "Failed to log feedback interaction";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    console.error(errorMessage);
  }
}

const PromotionalBanner = () => {
  const { classes } = useStyles();
  const user = useAppSelector((state) => state.user.user);
  const generations = useAppSelector((state) => state.gallery.generations);
  const [open, setOpen] = useLocalStorage("showFeedbackBanner", true);
  const showBanner = user && generations.length > 0 && open === true;

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(false);
    logInteraction();
  };

  return showBanner ? (
    <div className={classes.banner}>
      <div className={classes.bannerContent}>
        <div className={classes.bannerContentLeft}>
          <Typography variant="body1" className={classes.bannerText}>
            Please help us make the best website possible with your feedback
          </Typography>
        </div>
        <div className={classes.buttons}>
          <Button className={classes.declineButton} onClick={handleClose}>
            Close
          </Button>
          <a
            href="https://forms.gle/mDUzfwEesnkR2o6f9"
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            <Button className={classes.discordButton} onClick={handleClick}>
              Give Feedback
            </Button>
          </a>
          <CloseIcon onClick={handleClose} className={classes.closeIcon} />
        </div>
      </div>
    </div>
  ) : null;
};

export default PromotionalBanner;

"use client";

import { FC, forwardRef, useImperativeHandle, useEffect } from "react";
import { useRouter, useParams, usePathname } from "next/navigation";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

import { useStyles } from "./styles";
import { TransformationType } from "@/types/thread";
import { useAppSelector, useAppDispatch } from "@/redux/store";
import {
  createRoom,
  getNewGenerations,
  setSelectedGeneration,
  setSelectedModel,
} from "@/redux/actions";
import { useGenContext } from "@/context/GenContext";
import { useLocalStorage } from "@/hooks/useStorage";
import ListItem from "../ListItem";

export interface CreateGenHandle {
  handleGenerate: () => Promise<void>;
}

interface CreateGenProps {
  prompt: string;
  isSelected: boolean;
  submittingGen: boolean;
  setSubmittingGen: (submittingGen: boolean) => void;
  onUnfocus: () => void;
}

const CreateGen: FC<CreateGenProps & { ref?: any }> = forwardRef<
  CreateGenHandle,
  CreateGenProps
>(({ prompt, isSelected, submittingGen, setSubmittingGen, onUnfocus }, ref) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const pathname = usePathname();
  const { roomUUID } = useParams();
  const { dispatch: genDispatch } = useGenContext();
  const [_, setRedirectPath] = useLocalStorage("redirectPath", "/");

  const generations = useAppSelector((state) => state.room.generations);
  const user = useAppSelector((state) => state.user.user);
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
  const isSubscribed = Boolean(user && user?.subscriptionType !== "free");
  const hasActiveJobs = Boolean(user?.activeJobs && user.activeJobs.length > 0);
  const isThread = Boolean(roomUUID);

  const handleGenerate = async () => {
    if (!prompt || submittingGen) {
      onUnfocus();
      return;
    }

    const formattedPrompt = prompt.trim();

    if (!isAuthenticated) {
      setRedirectPath(`${pathname}?q=${encodeURIComponent(formattedPrompt)}`);
      router.push("/register");
      return;
    }

    if (!hasActiveJobs) {
      setSubmittingGen(true);

      dispatch(setSelectedGeneration(null));
      genDispatch({ type: "SET_PROMPT", payload: formattedPrompt });

      let ideaUUID: string | null = null;
      if (isThread && generations.length > 0) {
        ideaUUID = await dispatch(
          getNewGenerations({
            prompt: formattedPrompt,
          })
        ).unwrap();
      } else {
        ideaUUID = await dispatch(
          createRoom({
            prompt: formattedPrompt,
          })
        ).unwrap();
      }

      if (!isThread && ideaUUID) {
        router.push(`/idea/${ideaUUID}`);
      } else {
        setSubmittingGen(false);
      }
      onUnfocus();
    }
  };

  useImperativeHandle(ref, () => ({
    handleGenerate,
  }));

  useEffect(() => {
    function updateSelectedModel() {
      // Use the modelUsed in the most recent generation of selected thread by default
      if (isThread && generations.length > 0) {
        const selectedModel = generations[generations.length - 1].modelUsed;
        if (
          (!isSubscribed && selectedModel !== TransformationType.HD) ||
          isSubscribed
        ) {
          dispatch(setSelectedModel(selectedModel));
          return;
        }
      }

      const storedModel = localStorage.getItem("selectedModel");
      if (storedModel) {
        if (
          [
            TransformationType.HD,
            TransformationType.Flux_Pro,
            TransformationType.HD_Plus,
          ].includes(storedModel as TransformationType) &&
          !isSubscribed
        ) {
          localStorage.setItem("selectedModel", TransformationType.Turbo);
          dispatch(setSelectedModel(TransformationType.Turbo));
          return;
        }

        if (isSubscribed && !isThread) {
          dispatch(setSelectedModel(TransformationType.HD));
          return;
        }

        if (
          storedModel &&
          Object.values(TransformationType).includes(
            storedModel as TransformationType
          )
        ) {
          dispatch(setSelectedModel(storedModel as TransformationType));
          return;
        }
        localStorage.setItem("selectedModel", TransformationType.Turbo);
        return;
      }
    }

    updateSelectedModel();
  }, [generations]);

  if (!prompt) return null;

  return (
    <ListItem
      value={`Generate "${prompt.trim()}"`}
      isSelected={isSelected}
      icon={<AutoAwesomeIcon />}
      onSelect={handleGenerate}
      className={classes.container}
    />
  );
});

export default CreateGen;

CreateGen.displayName = "CreateGen";

"use client";

import { FC } from "react";
import Typography from "@mui/material/Typography";

import { useStyles } from "./styles";
import BackButton from "@/components/common/BackButton";

interface TutorialContentProps {
  heading: string;
  body: string;
  image?: string;
  tip?: string;
  onBack?: () => void;
}

const TutorialContent: FC<TutorialContentProps> = ({
  heading,
  body,
  image,
  tip,
  onBack,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        {onBack && (
          <BackButton onBack={onBack} className={classes.backButton} />
        )}
        <Typography variant="h2">{heading}</Typography>
      </div>
      <div className={classes.instructions}>
        <Typography variant="body1">{body}</Typography>
        {tip && (
          <Typography variant="body2" className={classes.tip}>
            Pro Tip: {tip}
          </Typography>
        )}
      </div>
      {image && (
        <div className={classes.gifContainer}>
          <img src={image} alt="Tutorial GIF" />
        </div>
      )}
    </div>
  );
};

export default TutorialContent;

"use client";

import { FC } from "react";
import { useRouter } from "next/navigation";

import { useStyles } from "./styles";
import Button from "@/components/common/PrimaryButton";
import CloseIcon from "@/components/icons/Close";

interface ChatBubbleProps {
  onClose: () => void;
}

const ChatBubble: FC<ChatBubbleProps> = ({ onClose }) => {
  const { classes } = useStyles();
  const router = useRouter();

  return (
    <div className={classes.chatBubble}>
      <div className={classes.chatBubbleInner}>
        <p className={classes.chatBubbleText}>Get access to HD gens</p>
        <Button onClick={() => router.push("/subscribe")} color="secondary">
          Subscribe
        </Button>
        <CloseIcon className={classes.closeButton} onClick={onClose} />
      </div>
    </div>
  );
};

export default ChatBubble;

"use client";

import { FC, MouseEvent, ReactNode } from "react";
import { useRouter } from "next/navigation";
import Typography from "@mui/material/Typography";

import { useStyles } from "./styles";
import { Nullable } from "types/common";
import { SubscriptionType } from "@/types/user";
import { useAppDispatch } from "@/redux/store";
import { setSelectedUsername } from "@/redux/actions";
import UserAvatar from "./UserAvatar";
import SubscriptionBadge from "@/components/profile/SubscriptionBadge";

interface UserDetailsProps {
  username: string;
  avatarUrl: Nullable<string>;
  showAvatar: boolean;
  isPrivate?: boolean;
  subscriptionType?: SubscriptionType;
  orientation?: "horizontal" | "vertical";
  avatarSize?: "small" | "medium" | "large";
  anon?: boolean;
  linkOverride?: string;
  hideSubscriptionLabel?: boolean;
  children?: ReactNode;
}

const UserDetails: FC<UserDetailsProps> = ({
  username,
  avatarUrl,
  showAvatar,
  isPrivate = false,
  subscriptionType,
  orientation = "horizontal",
  avatarSize = "medium",
  anon = false,
  linkOverride = "",
  hideSubscriptionLabel = false,
  children,
}) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { classes } = useStyles({
    isPrivate,
    showAvatar,
    orientation,
    avatarSize,
    anon,
  });

  const handleViewProfile = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!anon) {
      dispatch(setSelectedUsername(username));
    }
    router.push(linkOverride || `/profile/${username}`);
  };

  const handleControlsClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div className={classes.container} onClick={handleViewProfile}>
      <div className={classes.userContainer}>
        {showAvatar && (
          <UserAvatar
            avatarUrl={avatarUrl}
            username={username}
            avatarSize={avatarSize}
            subscriptionType={subscriptionType}
            hideSubscriptionBadge={!hideSubscriptionLabel}
          />
        )}
        <div className={classes.userDetails}>
          <Typography
            variant="body1"
            className={classes.username}
            title={username}
          >
            {username}
          </Typography>
          {!hideSubscriptionLabel && (
            <SubscriptionBadge subscriptionType={subscriptionType} />
          )}
        </div>
      </div>
      {children && <div onClick={handleControlsClick}>{children}</div>}
    </div>
  );
};

export default UserDetails;

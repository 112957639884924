import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  container: {
    position: "sticky",
    top: "50px",
    left: 0,
    padding: "1em 1em 0.75em 1em",
    width: "calc(100vw + 8px)",
    boxShadow: "0px 6px 3px -3px #DEE4EB",
    marginLeft: "-4px",
    backgroundColor: "#ffffff",
    zIndex: 1300,
    transition: "top 0.3s",
  },
}));

"use client";

import { useRouter } from "next/navigation";

import { AlertDialogType } from "@/types/alert";
import { useAppSelector, useAppDispatch } from "@/redux/store";
import { setSelectedAspectRatio, addAlertDialog } from "@/redux/actions";
import PopoverButton from "./PopoverButton";
import AspectRatioIcon from "./AspectRatioIcon";
import { TransformationType } from "@/types/thread";

export const ALL_ASPECT_RATIOS = [
  "9:16",
  "3:4",
  "2:3",
  "1:1",
  "5:4",
  "4:3",
  "3:2",
  "7:4",
  "16:9",
  "21:9",
];

export const FLUX_PRO_ASPECT_RATIOS = ["9:16", "3:4", "1:1", "4:3", "16:9"];

const AspectRatioSelect = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const user = useAppSelector((state) => state.user.user);
  const isSubscribed = user && user.subscriptionType !== "free";
  const selectedModel = useAppSelector((state) => state.room.selectedModel);
  const selectedAspectRatio = useAppSelector(
    (state) => state.room.selectedAspectRatio
  );

  const availableAspectRatios =
    selectedModel === TransformationType.Flux_Pro
      ? FLUX_PRO_ASPECT_RATIOS
      : ALL_ASPECT_RATIOS;

  const handleSelect = (newAspectRatio: string) => {
    dispatch(setSelectedAspectRatio(newAspectRatio));
    if (typeof localStorage !== undefined) {
      localStorage.setItem("selectedAspectRatio", newAspectRatio);
    }
  };

  const handleClick = () => {
    if (!isSubscribed) {
      dispatch(
        addAlertDialog({
          title: "Subscription Required",
          description: "You need to upgrade to use different aspect ratios!",
          nextButtonText: "Upgrade",
          type: AlertDialogType.SubscriptionRequired,
        })
      );
      return;
    }
  };

  return (
    <div onClick={handleClick}>
      <PopoverButton
        selected={selectedAspectRatio}
        options={availableAspectRatios.map((ratio) => ({
          value: ratio,
          icon: (
            <AspectRatioIcon
              w={Number(ratio.split(":")[0])}
              h={Number(ratio.split(":")[1])}
              active={selectedAspectRatio === ratio}
              disabled={!isSubscribed}
              aria-label="Aspect Ratio Select"
            />
          ),
          label: "",
        }))}
        onSelect={handleSelect}
        aspectRatioCount={availableAspectRatios.length}
        disabled={!isSubscribed}
      />
    </div>
  );
};

export default AspectRatioSelect;

import { FC } from "react";
import { BiArrowBack } from "react-icons/bi";

import { useStyles } from "./styles";

interface BackButtonProps {
  onBack: () => void;
  className?: string;
}

const BackButton: FC<BackButtonProps> = ({ onBack, className }) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.container, className)} onClick={onBack}>
      <BiArrowBack className={classes.icon} />
      <p className={classes.text}>BACK</p>
    </div>
  );
};

export default BackButton;

import FirstGenGif from "@/assets/gifs/first_gen.gif";
import ThreadGif from "@/assets/gifs/thread.gif";
import ImageRemix from "@/assets/gifs/image_remix.gif";
import LogoGif from "@/assets/gifs/logo.gif";
import ProfilePicture from "@/assets/thumbnails/profile_pic.webp";
import Anime from "@/assets/thumbnails/anime.webp";
import Art from "@/assets/thumbnails/art.webp";
import Tattoo from "@/assets/thumbnails/tattoo.webp";

export const getTutorialContent = (
  tutorialName: string
): { heading: string; body: string; image?: string; tip?: string } => {
  switch (tutorialName) {
    case "First Idea": {
      return {
        heading: tutorialName,
        body: "Creating your first images on Idyllic is as easy as entering an image description at the top of the feed then tapping return.",
        image: FirstGenGif.src,
        tip: "Select Create Idea from the top-left menu to view all controls.",
      };
    }
    case "Threads": {
      return {
        heading: tutorialName,
        body: "Whenever you create a new idea, a thread is created. Threads are a powerful way to iterate and refine your ideas. Each time you enter a text prompt, the context of all previous requests and images from the same thread is remembered.",
        image: ThreadGif.src,
      };
    }
    case "Image Remix": {
      return {
        heading: "Image Remix",
        body: "This tool lets you combine multiple source images to generate new ideas. You can upload a base image and up to 9 inspiration images. Free users can use this tool once per day. To get the most out of Idyllic, consider upgrading to our Unlimited plan.",
        image: ImageRemix.src,
        tip: "You don't need to include a base image. You can enter a text description and one or more inspiration images to generate new ideas.",
      };
    }
    case "Logos": {
      return {
        heading: tutorialName,
        body: "To generate a logo, all you need to do is include the word 'logo' in your description.",
        image: LogoGif.src,
        tip: "For best results, upgrade to unlimited to unlock HD logos.",
      };
    }
    case "Profile Pics": {
      return {
        heading: tutorialName,
        body: `To genereate stunning profile pics, just make sure to include "profile pic" or "PFP" in your description", you can combine this with other categories like "anime" e.g. "Anime girl profile pic".`,
        image: ProfilePicture.src,
      };
    }
    case "Anime": {
      return {
        heading: tutorialName,
        body: `To generate amazing anime, make sure to include "anime" in your description. Try referencing different styles or create inspired designs by uploading and remixing  your own pics.`,
        image: Anime.src,
      };
    }
    case "Art": {
      return {
        heading: tutorialName,
        body: `You're as creative as your vocabulary, include as many instructions as you like, and be as descriptive as possible. Include references to style, composition, era, type or any other descriptive element. E.g. a sphinx cat as a pharoah, vibrant and detailed impressionist oil painting.`,
        image: Art.src,
      };
    }
    case "Tattoos": {
      return {
        heading: tutorialName,
        body: "Create amazing tattoo concept designs on paper or skin by remixing your favourite reference pics, and further refining with text descriptions.",
        image: Tattoo.src,
      };
    }
    default: {
      return { heading: tutorialName, body: "Coming soon!" };
    }
  }
};

"use client";

import { FC, MouseEvent } from "react";
import Image from "next/image";

import { useStyles } from "./styles";
import { SubscriptionType } from "@/types/user";
import { AlertDialogType } from "@/types/alert";
import { useAppSelector, useAppDispatch } from "@/redux/store";
import { addAlertDialog } from "@/redux/actions";
import Icon from "@/assets/logos/icon.svg";

interface SubscriptionBadgeProps {
  subscriptionType?: SubscriptionType;
  iconOnly?: boolean;
  className?: string;
}

const SubscriptionBadge: FC<SubscriptionBadgeProps> = ({
  subscriptionType,
  iconOnly = false,
  className,
}) => {
  const dispatch = useAppDispatch();

  const isPro = Boolean(
    subscriptionType &&
      [SubscriptionType.Premium, SubscriptionType.Professional].includes(
        subscriptionType
      )
  );
  const currentUser = useAppSelector((state) => state.user.user);
  const currentUserSubscribed = Boolean(
    currentUser &&
      [SubscriptionType.Premium, SubscriptionType.Professional].includes(
        currentUser.subscriptionType
      )
  );
  const { classes, cx } = useStyles({ currentUserSubscribed });

  const handleUpgradeClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (!currentUserSubscribed) {
      dispatch(
        addAlertDialog({
          title: "Subscription Offer",
          description:
            "Upgrade your subscription to Premium or Professional. Get unlimited generations and edits, access to HD generations, no ads, priority queue and other features. Cancel anytime free of charge.",
          nextButtonText: "Upgrade",
          type: AlertDialogType.SubscriptionRequired,
        })
      );
    }
  };

  if (!isPro) return null;

  return (
    <div
      className={cx(classes.container, className)}
      onClick={handleUpgradeClick}
    >
      <img src={Icon.src} alt="Idyllic Icon" className={classes.badgeIcon} />
      {!iconOnly && <p className={classes.badgeText}>{subscriptionType}</p>}
    </div>
  );
};

export default SubscriptionBadge;

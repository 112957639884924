import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  listItemButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  link: {
    textDecoration: "none",
    paddingRight: "10px",
  },
  content: {
    fontWeight: "bold",
  },
}));

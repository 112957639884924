import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<{ currentUserSubscribed: boolean }>()(
  (theme, { currentUserSubscribed }) => ({
    container: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
      color: theme.palette.secondary.main,
      cursor: currentUserSubscribed ? "auto" : "pointer",
    },
    badgeIcon: {
      width: 16,
      height: 16,
      objectFit: "contain",
      [theme.breakpoints.down("md")]: {
        width: 14,
        height: 14,
      },
    },
    badgeText: {
      fontSize: "0.75rem",
      fontWeight: 700,
      textTransform: "capitalize",
    },
  })
);

"use client";

import { useState } from "react";
import dynamic from "next/dynamic";
import Box from "@mui/material/Box";
import MenuIcon from "@/components/icons/Menu";
import Drawer from "@mui/material/Drawer";

const Content = dynamic(() => import("../Content"));

const MobileSidebar = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen((prev) => !prev);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: {
          xs: "grid",
          sm: "grid",
          md: "grid",
          lg: "none",
          xl: "none",
          placeItems: "center",
        },
      }}
    >
      <MenuIcon
        onClick={toggleDrawer}
        style={{
          width: 30,
          height: 30,
          cursor: "pointer",
        }}
      />
      <Drawer
        anchor="left"
        open={open}
        onClose={closeDrawer}
        style={{ zIndex: 1400 }}
      >
        <Content closeDrawer={closeDrawer} />
      </Drawer>
    </Box>
  );
};

export default MobileSidebar;

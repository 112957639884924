import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  icon: {
    width: 18,
    height: 18,
    [theme.breakpoints.up("lg")]: {
      width: 22,
      height: 22,
    },
  },
  modalPosition: {
    position: "absolute",
    top: "55vh",
    left: "calc(335px + 50vw - 167px)",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("lg")]: {
      top: "50%",
      left: "50%",
    },
  },
  modal: {
    width: "60vw",
    maxHeight: "calc(100vh - 175px)",
    minHeight: "calc(100vh - 175px)",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      height: "100svh",
      maxWidth: "100vw",
      maxHeight: "100svh",
    },
  },
  resultsContainer: {
    marginTop: theme.spacing(4),
  },
  placeholderCard: {
    marginBottom: theme.spacing(2),
    borderRadius: "15px",
    [theme.breakpoints.down("md")]: {
      borderRadius: "10px",
    },
  },
}));

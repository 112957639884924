"use client";

import { FC } from "react";
import MUI_DIALOG from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Button from "@/components/common/PrimaryButton";

interface DialogProps {
  id: string;
  title: string;
  description?: string;
  onClose: () => void;
  prev: () => void;
  next: () => void;
  prevButtonText?: string;
  nextButtonText?: string;
  singleOption?: boolean;
}

const Dialog: FC<DialogProps> = ({
  id,
  title,
  description,
  onClose,
  prev = () => {},
  next = () => {},
  prevButtonText = "Cancel",
  nextButtonText = "Continue",
  singleOption = false,
}) => {
  const handleContinue = () => {
    onClose();
    next();
  };

  const handlePrev = () => {
    onClose();
    prev();
  };

  let displayContent = null;
  if (description) {
    displayContent = (
      <DialogContentText id="alert-dialog-description">
        {description}
      </DialogContentText>
    );
  }

  return (
    <MUI_DIALOG
      key={id}
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onKeyDown={(e) => {
        if (e.key === "Enter") handleContinue();
      }}
    >
      <DialogTitle data-testid="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>{displayContent}</DialogContent>
      <DialogActions>
        <Button onClick={handlePrev} data-testid="alert-dialog-cancel">
          {prevButtonText}
        </Button>
        {!singleOption && (
          <Button
            onClick={handleContinue}
            color="secondary"
            variant="contained"
            style={{ fontWeight: 700 }}
          >
            {nextButtonText}
          </Button>
        )}
      </DialogActions>
    </MUI_DIALOG>
  );
};

export default Dialog;

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  menu: {
    zIndex: 1500,
  },
  content: {
    width: 200,
    maxWidth: "100%",
    [theme.breakpoints.up("lg")]: {
      width: 320,
    },
  },
}));

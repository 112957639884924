import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(4),
  },
  heading: {
    width: "100%",
    position: "relative",
  },
  backButton: {
    position: "absolute",
    left: 0,
    top: "50%",
    transform: "translateY(-50%)",
  },
  instructions: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
  },
  tip: {
    fontWeight: 700,
  },
  gifContainer: {
    height: 500,
    "& > img": {
      height: "100%",
      width: "100%",
      objectFit: "contain",
    },
  },
}));

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<{
  isPrivate: boolean;
  orientation: "horizontal" | "vertical";
  showAvatar: boolean;
  avatarSize: "small" | "medium" | "large";
  anon: boolean;
}>()((theme, { isPrivate, orientation, showAvatar, avatarSize, anon }) => ({
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  container: {
    textAlign: "left",
    display: isPrivate ? "none" : "flex",
    alignItems: showAvatar ? "flex-start" : "center",
    gap: theme.spacing(1),
    cursor: anon ? "default" : "pointer",
  },
  userContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  userDetails: {
    display: "flex",
    flexDirection: orientation === "vertical" ? "column" : "row",
    gap: orientation === "vertical" ? 0 : theme.spacing(2),
    alignItems: "flex-start",
  },
  username: {
    position: "relative",
    fontSize: "0.75rem !important",
    fontWeight: 700,
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

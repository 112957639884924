import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<void, "icon" | "activeIcon">()(
  (theme, _params, classes) => ({
    buttonContainer: {
      color: "inherit",
      "&:hover": {
        [`& .${classes.icon}`]: {
          display: "none",
        },
        [`& .${classes.activeIcon}`]: {
          display: "grid",
        },
      },
    },
    icon: {
      display: "block",
      width: 18,
      height: 18,
      [theme.breakpoints.up("lg")]: {
        width: 22,
        height: 22,
      },
    },
    activeIcon: {
      display: "none",
      width: 18,
      height: 18,
      [theme.breakpoints.up("lg")]: {
        width: 22,
        height: 22,
      },
    },
    container: {
      height: "calc(100svh - 2em)",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up("lg")]: {
        height: "auto",
      },
    },
    content: {
      padding: theme.spacing(4, 0),
    },
  })
);

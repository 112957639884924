import React, {
  FC,
  MouseEvent,
  TouchEvent,
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import UndoIcon from "@mui/icons-material/Undo";

import { useStyles } from "./styles";
import { Point } from "@/types/common";
import Spinner from "@/components/common/Spinner";

interface InpaintCanvasProps {
  image: string | Blob;
  strokes: Point[][];
  setStrokes: (strokes: Point[][]) => void;
  generatingMask: boolean;
  isDrawing: boolean;
  setIsDrawing: (isDrawing: boolean) => void;
}

export interface InpaintCanvasHandle {
  generateMaskImage: (
    originalImageWidth: number,
    originalImageHeight: number
  ) => Promise<Blob | null>;
}

const InpaintCanvas: FC<InpaintCanvasProps & { ref?: any }> = forwardRef<
  InpaintCanvasHandle,
  InpaintCanvasProps
>(
  (
    { image, strokes, setStrokes, generatingMask, isDrawing, setIsDrawing },
    ref
  ) => {
    const { classes } = useStyles();
    const [canvasSize, setCanvasSize] = useState({ width: 500, height: 500 });
    const [loading, setLoading] = useState(true);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [cursorPosition, setCursorPosition] = useState<Point | null>(null);

    const getOffset = (
      e: MouseEvent<HTMLCanvasElement> | TouchEvent<HTMLCanvasElement>
    ) => {
      if ("touches" in e) {
        const { clientX, clientY } = e.touches[0];
        const rect = canvasRef.current?.getBoundingClientRect();
        if (!rect) return { offsetX: 0, offsetY: 0 };
        return {
          offsetX: clientX - rect.left,
          offsetY: clientY - rect.top,
        };
      }
      const { offsetX, offsetY } = e.nativeEvent;
      return { offsetX, offsetY };
    };

    const startDrawing = (
      e: MouseEvent<HTMLCanvasElement> | TouchEvent<HTMLCanvasElement>
    ) => {
      if (generatingMask) return;
      const { offsetX, offsetY } = getOffset(e);
      setIsDrawing(true);
      setStrokes([...strokes, [{ x: offsetX, y: offsetY }]]);
    };

    const draw = (
      e: MouseEvent<HTMLCanvasElement> | TouchEvent<HTMLCanvasElement>
    ) => {
      if (!isDrawing || !canvasRef.current || generatingMask) return;
      const { offsetX, offsetY } = getOffset(e);
      const lastStroke = strokes[strokes.length - 1];
      const newStroke: Point[] = [...lastStroke, { x: offsetX, y: offsetY }];
      setStrokes([...strokes.slice(0, -1), newStroke]);

      const ctx = canvasRef.current.getContext("2d");
      if (!ctx) return;
      ctx.lineJoin = "round";
      ctx.lineCap = "round";
      ctx.lineWidth = 30;
      ctx.strokeStyle = "#FF505E"; // Idyllic Pink

      ctx.beginPath();
      ctx.moveTo(
        lastStroke[lastStroke.length - 1].x,
        lastStroke[lastStroke.length - 1].y
      );
      ctx.lineTo(offsetX, offsetY);
      ctx.stroke();
    };

    const stopDrawing = () => {
      setIsDrawing(false);
    };

    const handleTouchMove = (e: TouchEvent<HTMLCanvasElement>) => {
      e.preventDefault();
      draw(e);
    };

    const handleMouseMove = (e: MouseEvent<HTMLCanvasElement>) => {
      const { offsetX, offsetY } = getOffset(e);
      setCursorPosition({ x: offsetX, y: offsetY });
      draw(e);
    };

    const clearCursor = (): void => {
      setCursorPosition(null);
    };

    const undoLastStroke = () => {
      if (strokes.length > 0) {
        setStrokes(strokes.slice(0, -1));
      }
    };

    const generateMaskImage = (
      originalImageWidth: number,
      originalImageHeight: number
    ): Promise<Blob | null> => {
      return new Promise((resolve) => {
        if (!canvasRef.current) return resolve(null);

        // Create a new canvas with the original image's dimensions
        const newCanvas = document.createElement("canvas");
        newCanvas.width = originalImageWidth;
        newCanvas.height = originalImageHeight;
        const newCtx = newCanvas.getContext("2d");
        if (!newCtx) return resolve(null);

        newCtx.fillStyle = "white";
        newCtx.fillRect(0, 0, newCanvas.width, newCanvas.height);

        // Calculate scaling factor to map strokes to original image size
        const scaleX = originalImageWidth / canvasRef.current.width;
        const scaleY = originalImageHeight / canvasRef.current.height;

        newCtx.lineJoin = "round";
        newCtx.lineCap = "round";
        newCtx.lineWidth = 30 * scaleX;
        newCtx.strokeStyle = "black";

        strokes.forEach((stroke) => {
          newCtx.beginPath();
          newCtx.moveTo(stroke[0].x * scaleX, stroke[0].y * scaleY);
          stroke.forEach((point) => {
            newCtx.lineTo(point.x * scaleX, point.y * scaleY);
          });
          newCtx.stroke();
        });

        newCanvas.toBlob((blob) => {
          resolve(blob);
        }, "image/png");
      });
    };

    useImperativeHandle(ref, () => ({
      generateMaskImage,
    }));

    useEffect(() => {
      const loadImage = () => {
        const img = new Image();
        if (typeof image === "string") {
          img.src = image;
        } else {
          const objectUrl = URL.createObjectURL(image);
          img.src = objectUrl;
        }

        img.onload = () => {
          const aspectRatio = img.width / img.height;

          // Maximum canvas height is 70% of the viewport height
          const maxHeight = window.innerHeight * 0.7;
          let viewportWidth =
            window.innerWidth < 768 ? window.innerWidth - 32 : 500;
          let canvasHeight = viewportWidth / aspectRatio;

          // If canvasHeight exceeds 80% of the viewport height, adjust width to maintain aspect ratio
          if (canvasHeight > maxHeight) {
            canvasHeight = maxHeight;
            viewportWidth = canvasHeight * aspectRatio;
          }

          setCanvasSize({
            width: viewportWidth,
            height: canvasHeight,
          });

          setLoading(false);

          if (typeof image === "object") {
            URL.revokeObjectURL(img.src);
          }
        };
      };

      loadImage();

      const handleResize = () => {
        loadImage();
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [image]);

    useEffect(() => {
      if (image instanceof Blob) {
        const objectUrl = URL.createObjectURL(image);
        setImageUrl(objectUrl);

        return () => {
          URL.revokeObjectURL(objectUrl);
        };
      } else if (typeof image === "string") {
        setImageUrl(image);
      }
    }, [image]);

    useEffect(() => {
      if (!canvasRef.current) return;
      const ctx = canvasRef.current.getContext("2d");
      if (ctx) {
        ctx.lineJoin = "round";
        ctx.lineCap = "round";
        ctx.lineWidth = 30;
        ctx.strokeStyle = "#FF505E"; // Idyllic Pink
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        strokes.forEach((stroke) => {
          ctx.beginPath();
          ctx.moveTo(stroke[0].x, stroke[0].y);
          stroke.forEach((point) => {
            ctx.lineTo(point.x, point.y);
          });
          ctx.stroke();
        });
      }
    }, [strokes, imageUrl, canvasSize]);

    return (
      <div className={classes.container}>
        {loading ? (
          <Spinner />
        ) : (
          <canvas
            ref={canvasRef}
            width={canvasSize.width}
            height={canvasSize.height}
            style={{
              backgroundImage: `url(${imageUrl})`,
              backgroundSize: "cover",
            }}
            onMouseDown={startDrawing}
            onMouseMove={handleMouseMove}
            onMouseUp={stopDrawing}
            onMouseLeave={clearCursor}
            onTouchStart={startDrawing}
            onTouchMove={handleTouchMove}
            onTouchEnd={stopDrawing}
          />
        )}
        {cursorPosition && !loading && (
          <div
            className={classes.cursor}
            style={{
              top: cursorPosition.y,
              left: cursorPosition.x,
            }}
          />
        )}
        {strokes.length > 0 && !loading && (
          <UndoIcon className={classes.undoButton} onClick={undoLastStroke} />
        )}
      </div>
    );
  }
);

export default InpaintCanvas;

InpaintCanvas.displayName = "InpaintCanvas";

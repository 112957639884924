"use client";

import { FC, useEffect, useRef } from "react";
import dynamic from "next/dynamic";

import { useStyles } from "./styles";

// const Search = dynamic(() => import("@/components/layout/Search"));
const GenInputs = dynamic(() => import("@/components/idea/GenInputs"));

interface DropdownWrapperProps {
  hideDropdown?: boolean;
}

const DropdownWrapper: FC<DropdownWrapperProps> = ({
  hideDropdown = false,
}) => {
  const { classes } = useStyles();
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const main = document.querySelector("main");
    if (!main) return;
    let prevScrollPos = main.scrollTop;
    const handleScroll = () => {
      const currentScrollPos = main.scrollTop;
      if (!containerRef.current) return;

      if (prevScrollPos >= currentScrollPos) {
        containerRef.current.style.top = "50px";
      } else if (currentScrollPos > 50) {
        containerRef.current.style.top = "-6em";
      }
      prevScrollPos = currentScrollPos;
    };

    main.addEventListener("scroll", handleScroll);

    return () => {
      main.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (hideDropdown) return null;

  return (
    <div className={classes.container} ref={containerRef}>
      <GenInputs />
    </div>
  );
};

export default DropdownWrapper;

"use client";

import { FC } from "react";

import { useStyles } from "./styles";
import ViewModeToggle from "./ViewModeToggle";
import HdToggle from "./HdToggle";
import AspectRatioSelect from "./AspectRatioSelect";
import ImageSelector from "./ImageSelector";

interface ControlsProps {
  allowEdits: boolean;
}

const Controls: FC<ControlsProps> = ({ allowEdits }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <ViewModeToggle />
      <HdToggle />
      <AspectRatioSelect />
      <ImageSelector disableEdits={!allowEdits} />
    </div>
  );
};

export default Controls;

"use client";

import { FC } from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { useStyles } from "./styles";

interface DropdownMenuItemProps {
  name: string;
  icon: JSX.Element;
  activeIcon?: JSX.Element;
  onSelect: (name: string) => void;
}

const DropdownMenuItem: FC<DropdownMenuItemProps> = ({
  name,
  icon,
  activeIcon = icon,
  onSelect,
}) => {
  const { classes } = useStyles();

  const handleSelect = () => {
    onSelect(name);
  };

  return (
    <MenuItem onClick={handleSelect} className={classes.container}>
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      <ListItemIcon className={classes.activeIcon}>{activeIcon}</ListItemIcon>
      <ListItemText className={classes.label}>{name}</ListItemText>
    </MenuItem>
  );
};

export default DropdownMenuItem;

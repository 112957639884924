import { makeStyles } from "tss-react/mui";

function getAvatarDimensions(size: string) {
  switch (size) {
    case "small":
      return 32;
    case "medium":
      return 46;
    case "large":
      return 64;
    default:
      return size;
  }
}

export const useStyles = makeStyles<{ size: string }>()((_theme, { size }) => ({
  container: {
    position: "relative",
  },
  avatar: {
    display: "flex",
    width: getAvatarDimensions(size),
    height: getAvatarDimensions(size),
  },
  subscriptionIcon: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
}));

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    [theme.breakpoints.up("lg")]: {
      marginTop: "auto",
      marginBottom: theme.spacing(-0.75),
    },
  },
}));

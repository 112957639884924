"use client";

import { FC, Suspense } from "react";
import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";

import { useStyles } from "./styles";
import { useAppSelector } from "@/redux/store";
import LogoWrapper from "./LogoWrapper";
import Tools from "./Tools";
import UserControls from "./UserControls";

const Sidebar = dynamic(() => import("../Sidebar"));
const GenInputs = dynamic(() => import("@/components/idea/GenInputs"));

interface NavbarProps {
  isMobile?: boolean;
  mode?: "light" | "dark";
  showGenInputs?: boolean;
}

const Navbar: FC<NavbarProps> = ({
  isMobile = false,
  mode = "light",
  showGenInputs = true,
}) => {
  const { classes } = useStyles({ mode });
  const pathname = usePathname() as string;
  const viewingUserId = useAppSelector((state) => state.user.user?.id);
  const existingRoomUserId = useAppSelector((state) => state.room.roomUserId);

  let publicIdeaPage = false;
  if (pathname.startsWith("/idea")) {
    publicIdeaPage =
      Boolean(existingRoomUserId) && viewingUserId !== existingRoomUserId;
  }
  const hideGenInputs =
    pathname === "/idea" ||
    (pathname.startsWith("/idea") && !publicIdeaPage) ||
    pathname === "/subscribe" ||
    pathname.startsWith("/category") ||
    !showGenInputs;

  return (
    <nav className={classes.container} id="navbar">
      <div className={classes.contentLeft}>
        {isMobile && <Sidebar isMobile />}
        <LogoWrapper mode={mode} />
      </div>
      <div className={classes.contentRight}>
        {!isMobile && !hideGenInputs && (
          <Suspense>
            <div className={classes.desktopGenControlsContainer}>
              <GenInputs overflowParent />
            </div>
          </Suspense>
        )}
        {pathname !== "/subscribe" && <Tools />}
        <Suspense>
          <UserControls />
        </Suspense>
      </div>
    </nav>
  );
};

export default Navbar;

"use client";

import { FC } from "react";
import NextLink from "next/link";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import { useStyles } from "./styles";
import { Notification as TNotification } from "@/types/social";
import { getDisplayedTimeAgo } from "@/utils/date";
import Avatar from "@/components/common/Avatar";

interface NotificationProps {
  notification: TNotification;
  handleClose: () => void;
}

const Notification: FC<NotificationProps> = ({ notification, handleClose }) => {
  const { classes } = useStyles();

  const renderNotificationContent = () => {
    let content = "";
    switch (notification.action) {
      case "comment":
        content = ` commented on your ${
          notification.resource === "idea" ? "thread" : "image"
        } `;
        break;
      case "rating":
        content = ` liked your ${notification.resource}. `;
        break;
      case "follow":
        content = " started following you. ";
        break;
      default:
        content = "";
        break;
    }
    return (
      <>
        <span className={classes.content}>
          {notification.sendingUser.username}
        </span>
        {content}
        <span style={{ color: "grey" }}>
          {getDisplayedTimeAgo(notification.created, true)}
        </span>
      </>
    );
  };

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton className={classes.listItemButton}>
          <ListItemIcon>
            {notification.read ? (
              ""
            ) : (
              <FiberManualRecordIcon fontSize={"small"} color="secondary" />
            )}
          </ListItemIcon>
          <NextLink
            href={`/profile/${notification.sendingUser.username}`}
            onClick={handleClose}
            key={notification.id}
            className={classes.link}
          >
            <ListItemAvatar>
              <Avatar
                src={
                  notification.sendingUser.avatarUrl
                    ? notification.sendingUser.avatarUrl
                    : ""
                }
                alt={`${notification.sendingUser.username}'s avatar`}
              />
            </ListItemAvatar>
          </NextLink>
          <ListItemText primary={renderNotificationContent()} />
        </ListItemButton>
      </ListItem>
    </>
  );
};

export default Notification;

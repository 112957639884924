"use client";

import IconButton from "@mui/material/IconButton";
import { TbLassoPolygon } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";

import { useStyles } from "./styles";
import { useGenContext } from "@/context/GenContext";
import ImagePreview from "./ImagePreview";
import { addAlertDialog } from "@/redux/actions";
import { AlertDialogType } from "@/types/alert";
import { useAppSelector, useAppDispatch } from "@/redux/store";

const ImagePreviews = () => {
  const { state, dispatch } = useGenContext();
  const reduxDispatch = useAppDispatch();
  const { images } = state;
  const { classes } = useStyles({ hasImages: images.length > 0 });
  const quotas = useAppSelector((state) => state.user.quotas);
  const inpaint = quotas.find((quota) => quota.resourceName === "inPaint");
  const dailyInpaintUsed = inpaint ? (inpaint.currentQuota ?? 0) <= 0 : false;

  const handleOpenInpaint = () => {
    if (images.length !== 1) return;
    if (dailyInpaintUsed) {
      reduxDispatch(
        addAlertDialog({
          title: "Subscription Required",
          description: "Upgrade to continue using Replace!",
          nextButtonText: "Upgrade",
          type: AlertDialogType.SubscriptionRequired,
        })
      );
    } else {
      dispatch({ type: "SET_IS_EDITING_MASK", payload: true });
      dispatch({ type: "SET_SELECTED_IMAGE", payload: images[0] });
    }
  };

  return (
    <div className={classes.container}>
      {images.map((image, index) => (
        <ImagePreview
          image={image}
          index={index}
          key={`Image Preview ${index + 1}`}
        />
      ))}
      {images.length === 1 && (
        <Tooltip title="Replace part of the image" arrow>
          <IconButton onClick={handleOpenInpaint} color="primary">
            <TbLassoPolygon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default ImagePreviews;

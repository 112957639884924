"use client";

import { FC, useRef, useEffect, useState } from "react";

import { useStyles } from "./styles";
import { useGenContext } from "@/context/GenContext";
import CloseIcon from "@/components/icons/Close";

interface ImagePreviewProps {
  image: Blob | string;
  index: number;
}

const ImagePreview: FC<ImagePreviewProps> = ({ image, index }) => {
  const { classes } = useStyles();
  const { state, deleteImage } = useGenContext();
  const { strokes, isEditingMask } = state;
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [imageSize, setImageSize] = useState({ width: 60, height: 60 });

  useEffect(() => {
    if (typeof image === "string") {
      setPreview(image);
    } else {
      setPreview(URL.createObjectURL(image));
    }

    return () => {
      if (typeof image !== "string" && preview) {
        URL.revokeObjectURL(preview);
      }
    };
  }, []);

  useEffect(() => {
    if (!preview) return;
    const imageObj = new Image();
    imageObj.src = preview;

    imageObj.onload = () => {
      const aspectRatio = imageObj.width / imageObj.height;
      const maxPreviewSize = 60;
      let width, height;

      if (aspectRatio >= 1) {
        width = maxPreviewSize;
        height = maxPreviewSize / aspectRatio;
      } else {
        width = maxPreviewSize * aspectRatio;
        height = maxPreviewSize;
      }

      setImageSize({ width, height });
    };

    return () => {
      if (typeof image !== "string") {
        URL.revokeObjectURL(preview);
      }
    };
  }, [image, preview]);

  useEffect(() => {
    if (!canvasRef.current || isEditingMask || strokes.length === 0 || !preview)
      return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const { width, height } = imageSize;
    const aspectRatio = width / height;

    // Maximum canvas height is 70% of the viewport height
    const maxHeight = window.innerHeight * 0.7;
    let originalCanvasWidth =
      window.innerWidth < 768 ? window.innerWidth - 32 : 500;
    let originalCanvasHeight = originalCanvasWidth / aspectRatio;

    // If canvasHeight exceeds 80% of the viewport height, adjust width to maintain aspect ratio
    if (originalCanvasHeight > maxHeight) {
      originalCanvasHeight = maxHeight;
      originalCanvasWidth = originalCanvasHeight * aspectRatio;
    }
    canvas.width = width;
    canvas.height = height;

    const imageObj = new Image();
    imageObj.src = preview;

    imageObj.onload = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(imageObj, 0, 0, width, height);

      if (strokes.length > 0) {
        ctx.lineJoin = "round";
        ctx.lineCap = "round";
        ctx.lineWidth = 5;
        ctx.strokeStyle = "#FF505E"; // Idyllic Pink

        const scaleFactorX = width / originalCanvasWidth;
        const scaleFactorY = height / originalCanvasHeight;

        strokes.forEach((stroke) => {
          if (stroke.length > 0) {
            ctx.beginPath();
            ctx.moveTo(stroke[0].x * scaleFactorX, stroke[0].y * scaleFactorY);
            stroke.forEach((point) => {
              ctx.lineTo(point.x * scaleFactorX, point.y * scaleFactorY);
            });
            ctx.stroke();
          }
        });
      }
    };
  }, [strokes, preview, isEditingMask, imageSize]);

  return (
    <div className={classes.imagePreview}>
      {strokes.length === 0 && preview && (
        <img
          src={preview}
          alt="Preview"
          className={classes.image}
          style={{ width: imageSize.width, height: imageSize.height }}
        />
      )}
      {!isEditingMask && strokes.length > 0 && (
        <canvas
          ref={canvasRef}
          className={classes.image}
          style={{
            width: `${imageSize.width}px`,
            height: `${imageSize.height}px`,
          }}
        />
      )}
      <CloseIcon
        className={classes.deleteImageButton}
        onClick={() => deleteImage(index)}
      />
    </div>
  );
};

export default ImagePreview;

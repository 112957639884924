"use client";

import { FC } from "react";
import Switch from "@mui/material/Switch";

import { useStyles } from "./styles";
import { AlertDialogType } from "@/types/alert";
import { useAppSelector, useAppDispatch } from "@/redux/store";
import { updateSafeSearch, addAlertDialog } from "@/redux/actions";

interface SafeSearchToggleProps {
  size?: "small" | "medium";
  hideLabel?: boolean;
}

const SafeSearchToggle: FC<SafeSearchToggleProps> = ({
  size = "small",
  hideLabel = false,
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const isSubscribed = Boolean(user && user.subscriptionType !== "free");
  const authLoading = useAppSelector((state) => state.user.authLoading);

  const handleUpdateSafeSearch = () => {
    if (!user || (!isSubscribed && user.safeSearch)) {
      dispatch(
        addAlertDialog({
          title: "Subscription Required",
          description: "You must be subscribed to disable safe search!",
          nextButtonText: "Upgrade",
          type: AlertDialogType.SubscriptionRequired,
        })
      );
      return;
    }

    const existingSetting = user.safeSearch;
    dispatch(updateSafeSearch(!existingSetting));
  };

  const safeSearchEnabled =
    user?.safeSearch !== undefined ? user.safeSearch : true;

  if (authLoading) return null;

  return (
    <div className={classes.container}>
      {!hideLabel && <p className={classes.label}>Safe Search</p>}
      <Switch
        color="primary"
        size={size}
        checked={safeSearchEnabled}
        onChange={handleUpdateSafeSearch}
        inputProps={{ "aria-label": "Safe Search Toggle" }}
      />
    </div>
  );
};

export default SafeSearchToggle;

"use client";

import { FC } from "react";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

import { useStyles } from "./styles";
import { useAppSelector } from "@/redux/store";
import ShareButton from "@/components/social/share/ShareButton";
import GenerationHelpRequest from "@/components/idea/Generation/GenerationHelpRequest";

interface ThreadControlsProps {
  toggleComments?: () => void;
}

const ThreadControls: FC<ThreadControlsProps> = ({ toggleComments }) => {
  const { classes } = useStyles();
  const existingRoomUserId = useAppSelector((state) => state.room.roomUserId);
  const numberOfComments = useAppSelector(
    (state) => state.comments.comments.length
  );

  return (
    <div className={classes.container}>
      <GenerationHelpRequest />
      {existingRoomUserId && <ShareButton />}
      {Boolean(toggleComments) && (
        <IconButton
          className={classes.socialButton}
          onClick={toggleComments}
          aria-label="Comments"
        >
          <Badge badgeContent={numberOfComments} color="secondary">
            <ChatBubbleOutlineIcon />
          </Badge>
        </IconButton>
      )}
    </div>
  );
};

export default ThreadControls;

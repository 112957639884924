"use client";

import { FC } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import { useStyles } from "./styles";

interface TutorialCardProps {
  label: string;
  image: string;
  onClick: () => void;
}

const TutorialCard: FC<TutorialCardProps> = ({ label, image, onClick }) => {
  const { classes } = useStyles();

  return (
    <Card onClick={onClick} className={classes.container}>
      <div className={classes.imageContainer}>
        <img src={image} alt={label} />
      </div>
      <div className={classes.label}>
        <Typography variant="body1">{label}</Typography>
      </div>
    </Card>
  );
};

export default TutorialCard;

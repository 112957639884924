"use client";

import { useState } from "react";
import NextLink from "next/link";
import List from "@mui/material/List";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { useStyles } from "./styles";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { markNotificationsRead } from "@/redux/actions";
import Modal from "@/components/common/Modal";
import Notification from "@/components/social/notifications/Notification";
import TypingSpinner from "@/components/common/TypingSpinner";
import OutlinedIcon from "@/components/icons/BellOutlined";
import FilledIcon from "@/components/icons/Bell";

const Notifications = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const [showModal, setShowModal] = useState(false);
  const notifications = useAppSelector(
    (state) => state.notifications.notifications
  );
  const loading = useAppSelector((state) => state.notifications.loading);
  const unreadCount = notifications.filter(
    (notification) => !notification.read
  ).length;

  const handleToggle = () => {
    setShowModal((prevValue) => !prevValue);
  };

  const handleClose = () => {
    setShowModal(false);
    dispatch(markNotificationsRead({ notifications }));
  };

  return (
    <>
      <IconButton
        color={unreadCount > 0 ? "secondary" : "primary"}
        onClick={handleToggle}
        aria-label="toggle view tutorials"
        className={classes.buttonContainer}
      >
        <Badge badgeContent={unreadCount} color="secondary">
          <OutlinedIcon className={classes.icon} />
          <FilledIcon className={classes.activeIcon} />
        </Badge>
        <Modal
          open={showModal}
          onClose={handleClose}
          disableBackdropClose
          className={classes.modal}
        >
          <div className={classes.container}>
            <div className={classes.content}>
              <Typography variant="h2">Notifications</Typography>
              <div className={classes.placeholder}>
                {loading && <TypingSpinner />}
                {!loading && notifications.length === 0 && (
                  <Typography variant="body1">
                    You don&apos;t have any notifications yet.
                  </Typography>
                )}
              </div>
              <List sx={{ width: "100%" }}>
                {notifications.map((notification) => (
                  <NextLink
                    href={`${notification.redirectPath}`}
                    onClick={handleClose}
                    key={notification.id}
                    className={classes.link}
                  >
                    <Notification
                      notification={notification}
                      handleClose={handleClose}
                    />
                  </NextLink>
                ))}
              </List>
            </div>
          </div>
        </Modal>
      </IconButton>
    </>
  );
};

export default Notifications;

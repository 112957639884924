"use client";

import {
  FC,
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
  useState,
  useRef,
} from "react";
import { useSearchParams } from "next/navigation";
import axios from "axios";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";

import { useStyles } from "./styles";
import SafeSearchToggle from "@/components/settings/SafeSearchToggle";
import Spinner from "@/components/common/TypingSpinner";
import ListItem from "./ListItem";
import CreateGen, { CreateGenHandle } from "./CreateGen";
import CloseIcon from "@/components/icons/Close";

interface SearchProps {
  loading: boolean;
  onPromptChange?: (prompt: string) => void;
  onSearch?: (searchValue: string) => void;
  onClearSearch?: () => void;
  onClose?: () => void;
  showSafeSearchToggle?: boolean;
}

const Search: FC<SearchProps> = ({
  loading,
  onPromptChange = () => {},
  onSearch = () => {},
  onClearSearch = () => {},
  onClose = () => {},
  showSafeSearchToggle = false,
}) => {
  const { classes } = useStyles();
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("md")
  );
  const searchParams = useSearchParams();
  const searchQuery = searchParams?.get("q");
  const [query, setQuery] = useState(searchQuery || "");
  const [listOptions, setListOptions] = useState<string[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const [submittingGen, setSubmittingGen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const listItemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const createGenRef = useRef<CreateGenHandle | null>(null);
  const touchStartY = useRef<number | null>(null);
  const touchMoved = useRef(false);

  const handleQueryChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    if (newQuery) {
      setIsFocused(true);
      onPromptChange(newQuery);
      try {
        const response = await axios.get(
          `${
            process.env.NEXT_PUBLIC_API_URL
          }/autocomplete/?prefix=${encodeURIComponent(newQuery)}`
        );
        const suggestions = response.data as string[];
        setListOptions(suggestions);
      } catch (error) {
        console.error(error);
      }
    } else {
      setListOptions([]);
      onPromptChange("");
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSearch(query);
  };

  const handleSearch = (searchValue?: string) => {
    if (!searchValue) return;
    setQuery(searchValue);
    onSearch(searchValue);
    handleUnfocus();
  };

  const handleClearSearch = () => {
    setQuery("");
    setListOptions([]);
    onClearSearch();
  };

  const scrollToListItem = (index: number) => {
    if (listItemRefs.current?.[index]) {
      listItemRefs.current[index]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setSelectedIndex((prevIndex) => {
        const nextIndex = prevIndex < listOptions.length ? prevIndex + 1 : 0;
        scrollToListItem(nextIndex);
        return nextIndex;
      });
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setSelectedIndex((prevIndex) => {
        const nextIndex = prevIndex > 0 ? prevIndex - 1 : listOptions.length;
        scrollToListItem(nextIndex);
        return nextIndex;
      });
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (selectedIndex === 0 && createGenRef.current) {
        createGenRef.current.handleGenerate();
      } else if (selectedIndex >= 1) {
        handleSearch(listOptions[selectedIndex - 1]);
      } else {
        handleSearch(query);
      }
    } else if (e.key === "Escape") {
      handleUnfocus();
    }
  };

  const handleUnfocus = () => {
    setIsFocused(false);
    setSelectedIndex(-1);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    touchMoved.current = false;
    touchStartY.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (touchStartY.current !== null) {
      const deltaY = Math.abs(e.touches[0].clientY - touchStartY.current);
      if (deltaY > 10) {
        touchMoved.current = true;
      }
    }
  };

  const handleBlur = () => {
    if (query && isMobile) {
      handleSearch(query);
    } else {
      handleUnfocus();
    }
  };

  const updateSubmittingGen = (isSubmitting: boolean) => {
    setSubmittingGen(isSubmitting);
    if (!isSubmitting) {
      onClose();
    }
  };

  return (
    <div className={classes.container}>
      <form className={classes.searchInputContainer} onSubmit={handleSubmit}>
        <input
          aria-label="Search Idyllic"
          type="text"
          placeholder={
            isMobile
              ? "Search Idyllic for something..."
              : "Search Idyllic for something or generate..."
          }
          className={classes.searchInput}
          value={query}
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
          onChange={handleQueryChange}
          onKeyDown={handleKeyDown}
        />
        {query && (
          <CloseIcon
            className={classes.closeIcon}
            aria-label="Clear search"
            onClick={handleClearSearch}
          />
        )}
        {(loading || submittingGen) && <Spinner />}
        {query && isFocused && (
          <Paper className={classes.dropdown}>
            <CreateGen
              prompt={query}
              isSelected={selectedIndex === 0}
              onUnfocus={handleUnfocus}
              submittingGen={submittingGen}
              setSubmittingGen={updateSubmittingGen}
              ref={createGenRef}
            />
            {listOptions.map((option, index) => (
              <ListItem
                key={option}
                value={option}
                isSelected={index + 1 === selectedIndex}
                onSelect={handleSearch}
                ref={(el) => {
                  listItemRefs.current[index] = el;
                }}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleSearch}
              />
            ))}
          </Paper>
        )}
      </form>
      {showSafeSearchToggle && (
        <div className={classes.safeSearchToggle}>
          <SafeSearchToggle />
        </div>
      )}
    </div>
  );
};

export default Search;

"use client";

import { useStyles } from "./styles";
import SearchModal from "./SearchModal";
import TutorialModal from "@/components/tutorials/TutorialModal";
import Notifications from "components/social/notifications/Notifications";

const Tools = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <SearchModal />
      <TutorialModal />
      <Notifications />
    </div>
  );
};

export default Tools;

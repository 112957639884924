import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  icon: {
    color: theme.palette.primary.main,
    overflow: "visible",
    "&:hover": {
      color: theme.palette.secondary.light,
    },
  },
  active: {
    color: theme.palette.secondary.main,
  },
  disabled: {
    color: "#cccccc",
    cursor: "not-allowed",
    "&:hover": {
      color: "#cccccc",
    },
  },
}));

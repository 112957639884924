import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  iconContainer: {
    display: "grid",
    placeItems: "center",
  },
  icon: {
    fontSize: "1.5rem",
  },
}));

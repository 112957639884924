import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "flex-end",
    },
  },
  searchInputContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(4),
    width: "100%",
    backgroundColor: "#ececec",
    borderRadius: 8,
    padding: theme.spacing(1, 3),
    maxWidth: "800px",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "100%",
    },
  },
  searchInput: {
    width: "100%",
    height: "25px",
    boxSizing: "border-box",
    fontSize: "1rem",
    fontFamily: "inherit",
    border: "none",
    backgroundColor: "#ececec",
    "&:focus-within": {
      borderColor: theme.palette.primary.main,
    },
    "&:focus": {
      outline: "none",
    },
  },
  closeIcon: {
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  dropdown: {
    position: "absolute",
    maxHeight: "50vh",
    overflowY: "auto",
    top: "100%",
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "0 0 8px 8px",
    marginTop: theme.spacing(1),
    zIndex: 3000,
    padding: theme.spacing(4, 0),
  },
  safeSearchToggle: {
    minWidth: 120,
  },
}));

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<void, "label">()(
  (theme, _params, classes) => ({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "150px",
      height: "150px",
      backgroundColor: "#ffffff",
      borderRadius: "4px",
      cursor: "pointer",
      "&:hover": {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        [`& .${classes.label}`]: {
          color: theme.palette.secondary.main,
        },
      },
    },
    imageContainer: {
      height: "120px",
      "& > img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
    label: {
      padding: theme.spacing(1),
      color: theme.palette.primary.main,
    },
  })
);

"use client";

import { FC, useState } from "react";

import { Nullable } from "@/types/common";
import { getTutorialContent } from "./helpers";
import TutorialMenu from "../TutorialMenu";
import TutorialContent from "../TutorialContent";

interface TutorialPanelProps {
  heading?: string;
  description?: string;
}

const TutorialPanel: FC<TutorialPanelProps> = ({
  heading = "Tutorials",
  description = "",
}) => {
  const [selectedTutorial, setSelectedTutorial] =
    useState<Nullable<string>>(null);

  const handleBack = () => {
    setSelectedTutorial(null);
  };

  return (
    <>
      {selectedTutorial ? (
        <TutorialContent
          {...getTutorialContent(selectedTutorial)}
          onBack={handleBack}
        />
      ) : (
        <TutorialMenu
          heading={heading}
          description={description}
          selectTutorial={setSelectedTutorial}
        />
      )}
    </>
  );
};

export default TutorialPanel;

"use client";

import { FC } from "react";
import MUI_Avatar from "@mui/material/Avatar";

import { useStyles } from "./styles";
import Image from "../Image";

interface AvatarProps {
  src: string;
  alt: string;
  className?: string;
  onClick?: () => void;
  width?: number;
  height?: number;
}

const Avatar: FC<AvatarProps> = ({
  src,
  alt,
  className,
  onClick,
  width = 55,
  height = 55,
}) => {
  const { classes, cx } = useStyles();

  return src.includes("files") ? (
    <div className={cx(classes.container, className)} onClick={onClick}>
      <Image
        width={width}
        height={height}
        src={src}
        alt={alt}
        className={classes.image}
      />
    </div>
  ) : (
    <MUI_Avatar
      className={cx(classes.container, className)}
      src={src}
      alt={alt}
      onClick={onClick}
    />
  );
};

export default Avatar;

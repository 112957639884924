import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<{ mode: "light" | "dark" }>()(
  (theme, { mode }) => ({
    container: {
      position: "fixed",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "calc(100vw + 8px)",
      height: 50,
      padding: theme.spacing(3, 4),
      zIndex: 1450,
      marginLeft: "-4px",
      color: mode === "light" ? theme.palette.primary.main : "#ffffff",
      backgroundColor: mode === "light" ? "#ffffff" : "#0D1220",
      boxShadow: mode === "light" ? "0px 6px 3px -3px #DEE4EB" : "none",
      [theme.breakpoints.up("lg")]: {
        height: 78,
        padding: theme.spacing(3, 8),
      },
    },
    contentLeft: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(3),
    },
    contentRight: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
      alignItems: "center",
      gap: theme.spacing(3),
    },
    desktopGenControlsContainer: {
      width: "60vw",
      marginRight: theme.spacing(3),
    },
  })
);

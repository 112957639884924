"use client";

import { FC, useState, useEffect } from "react";
import { useRouter } from "next/navigation";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";

import { useStyles } from "./styles";
import { TransformationType } from "@/types/thread";
import { AlertDialogType } from "@/types/alert";
import { useAppSelector, useAppDispatch } from "@/redux/store";
import {
  createRoom,
  setSelectedModel,
  setSelectedGeneration,
  getNewGenerations,
  setCancelled,
  setCancellationReason,
  setSelectedAspectRatio,
  setViewMode,
  addAlertDialog,
} from "@/redux/actions";
import { useGenContext } from "@/context/GenContext";
import ThreadControls from "./ThreadControls";
import Controls from "./Controls";
import PromptInput from "./PromptInput";
import SafeSearchToggle from "@/components/settings/SafeSearchToggle";
import InpaintModal from "./InpaintModal";

interface GenInputsProps {
  toggleComments?: () => void;
  roomUUID?: string;
  overflowParent?: boolean;
  hideSafeSearch?: boolean;
  landingPageName?: string;
  landingPageSlug?: string;
  defaultAspectRatio?: string | null;
}

const GenInputs: FC<GenInputsProps> = ({
  toggleComments,
  roomUUID,
  overflowParent = false,
  hideSafeSearch = false,
  landingPageName,
  landingPageSlug,
  defaultAspectRatio,
}) => {
  const { classes } = useStyles({ overflowParent });
  const dispatch = useAppDispatch();
  const router = useRouter();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const user = useAppSelector((state) => state.user.user);
  const quotas = useAppSelector((state) => state.user.quotas);
  const { state, dispatch: genDispatch } = useGenContext();
  const { images, imageMask } = state;
  const [inputError, setInputError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
  const isSubscribed = Boolean(user && user?.subscriptionType !== "free");
  const isThread = Boolean(roomUUID);

  const remix = quotas.find((quota) => quota.resourceName === "remix");
  const inpaint = quotas.find((quota) => quota.resourceName === "inPaint");
  const dailyRemixUsed = remix ? (remix.currentQuota ?? 0) <= 0 : false;
  const dailyInpaintUsed = inpaint ? (inpaint.currentQuota ?? 0) <= 0 : false;
  const allowEdits =
    isSubscribed || (!isSubscribed && (!dailyRemixUsed || !dailyInpaintUsed));

  const hasActiveJobs = Boolean(user?.activeJobs && user.activeJobs.length > 0);
  const cancelled = useAppSelector((state) => state.room.cancelled);
  const cancellationReason = useAppSelector(
    (state) => state.room.cancellationReason
  );
  const generations = useAppSelector((state) => state.room.generations);

  const handlePromptChange = (newValue: string) => {
    if (cancelled !== null) dispatch(setCancelled(null));
    if (cancellationReason !== null) dispatch(setCancellationReason(null));

    if (newValue.length > 1000) {
      setInputError("Description must be less than 1000 characters");
      return;
    }
    setInputError("");

    const aspectRatioPattern = /--(ar|aspect-ratio|aspect) (\d+:\d+)/;
    const match = newValue.match(aspectRatioPattern);

    if (match) {
      const aspectRatio = match[2];
      dispatch(setSelectedAspectRatio(aspectRatio));
    }

    genDispatch({ type: "SET_PROMPT", payload: newValue });
  };

  const handleSubmit = async () => {
    if (isSubmitting) return;

    if (!isAuthenticated) {
      localStorage.setItem("draftPrompt", state.prompt);

      router.push("/register");
      return;
    }

    if (!prompt && images.length === 0) {
      setInputError("Please enter a prompt");
      return;
    }

    if (!hasActiveJobs) {
      if (imageMask && dailyInpaintUsed) {
        dispatch(
          addAlertDialog({
            title: "Subscription Required",
            description: "Upgrade to continue using Replace!",
            nextButtonText: "Upgrade",
            type: AlertDialogType.SubscriptionRequired,
          })
        );
        return;
      }

      if (images.length > 0 && dailyRemixUsed) {
        dispatch(
          addAlertDialog({
            title: "Subscription Required",
            description: "Upgrade to do more image edits!",
            nextButtonText: "Upgrade",
            type: AlertDialogType.SubscriptionRequired,
          })
        );
        return;
      }

      setIsSubmitting(true);

      dispatch(setSelectedGeneration(null));
      const formattedPrompt = state.prompt.trim();

      let ideaUUID: string | null = null;
      if (isThread && Array.isArray(generations) && generations?.length > 0) {
        ideaUUID = await dispatch(
          getNewGenerations({
            prompt: formattedPrompt,
            inspiration: images,
            defaultAspectRatio,
            imageMask,
          })
        ).unwrap();
      } else {
        ideaUUID = await dispatch(
          createRoom({
            prompt: formattedPrompt,
            inspiration: images,
            landingPageSlug,
            defaultAspectRatio,
            imageMask,
          })
        ).unwrap();
      }

      if (!isThread && ideaUUID) {
        router.push(`/idea/${ideaUUID}`);
      } else {
        setIsSubmitting(false);
      }
    }
  };

  function getInputErrorMessage(cancellationReason: string | null) {
    switch (cancellationReason) {
      case "nsfw_quota_met":
        setInputError("You need to upgrade to generate more NSFW images");
        break;
      case "inappropriate_image":
        setInputError("You can't enhance inappropriate images");
        break;
      case "image_load_failure":
        setInputError("Failed to load image");
        break;
      default:
        setInputError("Description must be appropriate");
    }
  }

  useEffect(() => {
    if (cancelled) {
      getInputErrorMessage(cancellationReason);
      setIsSubmitting(false);
    } else {
      setInputError("");
    }
  }, [cancelled]);

  useEffect(() => {
    return () => {
      setInputError("");

      if (user && isSubscribed && !defaultAspectRatio) {
        const selectedAspectRatio = localStorage.getItem("selectedAspectRatio");
        if (selectedAspectRatio) {
          dispatch(setSelectedAspectRatio(selectedAspectRatio));
        }

        const viewMode = user.defaultViewMode;
        dispatch(setViewMode(viewMode));
      }
    };
  }, []);

  useEffect(() => {
    function updateSelectedModel() {
      // Use the modelUsed in the most recent generation of selected thread by default
      if (isThread && Array.isArray(generations) && generations?.length > 0) {
        const selectedModel = generations[generations.length - 1]?.modelUsed;
        if (
          (!isSubscribed &&
            selectedModel &&
            selectedModel !== TransformationType.HD) ||
          isSubscribed
        ) {
          dispatch(setSelectedModel(selectedModel));
          return;
        }
      }

      const storedModel = localStorage.getItem("selectedModel");
      if (storedModel) {
        if (
          [
            TransformationType.HD,
            TransformationType.Flux_Pro,
            TransformationType.HD_Plus,
          ].includes(storedModel as TransformationType) &&
          !isSubscribed
        ) {
          localStorage.setItem("selectedModel", TransformationType.Turbo);
          dispatch(setSelectedModel(TransformationType.Turbo));
          return;
        }

        if (isSubscribed && !isThread) {
          dispatch(setSelectedModel(TransformationType.HD));
          return;
        }

        if (
          storedModel &&
          Object.values(TransformationType).includes(
            storedModel as TransformationType
          )
        ) {
          dispatch(setSelectedModel(storedModel as TransformationType));
          return;
        }
        localStorage.setItem("selectedModel", TransformationType.Turbo);
        return;
      }
    }

    updateSelectedModel();
  }, [generations]);

  return (
    <>
      <div className={classes.container}>
        {isThread && <ThreadControls toggleComments={toggleComments} />}
        <div className={classes.mainControls}>
          {isDesktop && <Controls allowEdits={allowEdits} />}
          <div className={classes.inputContainer}>
            <PromptInput
              prompt={state.prompt}
              setPrompt={handlePromptChange}
              onSubmit={handleSubmit}
              loading={hasActiveJobs || isSubmitting}
              error={inputError}
              placeholder={
                landingPageName ? `Describe your ${landingPageName}` : undefined
              }
            />
          </div>
          <div className={classes.bottomControls}>
            {!isDesktop && <Controls allowEdits={allowEdits} />}
            {!hideSafeSearch && <SafeSearchToggle />}
          </div>
        </div>
      </div>
      <InpaintModal />
    </>
  );
};

export default GenInputs;

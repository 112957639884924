"use client";

import { FC } from "react";

import { useStyles } from "./styles";
import { SubscriptionType } from "@/types/user";
import Avatar from "@/components/common/Avatar";
import SubscriptionBadge from "@/components/profile/SubscriptionBadge";

interface UserAvatarProps {
  avatarUrl: string | null;
  username: string;
  subscriptionType?: SubscriptionType;
  avatarSize?: string;
  hideSubscriptionBadge?: boolean;
}

const UserAvatar: FC<UserAvatarProps> = ({
  avatarUrl,
  username,
  subscriptionType,
  avatarSize = "medium",
  hideSubscriptionBadge = false,
}) => {
  const { classes } = useStyles({ size: avatarSize });

  return (
    <div className={classes.container}>
      <Avatar
        className={classes.avatar}
        src={avatarUrl ? avatarUrl : ""}
        alt={`${username}'s avatar`}
      />
      {!hideSubscriptionBadge && (
        <SubscriptionBadge
          subscriptionType={subscriptionType}
          iconOnly
          className={classes.subscriptionIcon}
        />
      )}
    </div>
  );
};

export default UserAvatar;

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<void, "icon" | "activeIcon">()(
  (theme, _params, classes) => ({
    container: {
      padding: theme.spacing(3),
      "&:hover": {
        backgroundColor: "#f5f5f5",
        [`& .${classes.icon}`]: {
          display: "none",
        },
        [`& .${classes.activeIcon}`]: {
          display: "grid",
        },
      },
    },
    icon: {
      color: theme.palette.primary.main,
      "& > svg": {
        width: 18,
        height: 18,
      },
      [theme.breakpoints.up("lg")]: {
        "& > svg": {
          width: 24,
          height: 24,
        },
      },
    },
    activeIcon: {
      display: "none",
      color: theme.palette.primary.main,
    },
    label: {
      fontSize: "0.88rem",
      fontWeight: 400,
      color: theme.palette.primary.main,
      [theme.breakpoints.up("lg")]: {
        fontSize: "1rem",
      },
    },
  })
);

"use client";

import { FC } from "react";
import { useRouter, usePathname } from "next/navigation";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";

import { useAppDispatch } from "@/redux/store";
import { resetRoom } from "@/redux/actions";
import Logo from "@/components/common/Logo";

interface LogoWrapperProps {
  mode?: "light" | "dark";
}

const LogoWrapper: FC<LogoWrapperProps> = ({ mode = "light" }) => {
  const dispatch = useAppDispatch();
  const pathname = usePathname() as string;
  const router = useRouter();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const handleLogoClick = () => {
    if (pathname.startsWith("/quiz")) {
      router.push("/quiz");
      return;
    }
    dispatch(resetRoom());
    router.push("/");
  };

  return (
    <Logo
      onClick={handleLogoClick}
      variant={isDesktop ? "logo" : "icon"}
      width={isDesktop ? "100px" : "26px"}
      mode={mode}
    />
  );
};

export default LogoWrapper;

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<{
  disabled: boolean;
}>()((theme, { disabled }) => ({
  container: {
    width: "100%",
    boxSizing: "border-box",
    padding: theme.spacing(1, 3),
    border: "1px solid #C4C4C4",
    background: "#ffffff",
    borderRadius: 8,
    "&:focus-within": {
      borderColor: theme.palette.primary.main,
    },
  },
  textAreaContainer: {
    display: "flex",
  },
  textArea: {
    width: "100%",
    height: "25px",
    boxSizing: "border-box",
    color: theme.palette.primary.main,
    fontSize: "1rem",
    fontFamily: "inherit",
    lineHeight: "25px",
    border: "none",
    resize: "none",
    overflow: "hidden",
    "&:focus": {
      outline: "none",
    },
  },
  inputAdornment: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: disabled ? "#ececec" : theme.palette.secondary.main,
    cursor: disabled ? "auto" : "pointer",
    paddingLeft: theme.spacing(2),
    "&:hover": {
      opacity: 0.8,
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: "0.75rem",
  },
}));

"use client";

import { useRouter } from "next/navigation";

import { AlertDialogType } from "@/types/alert";
import { TransformationType } from "@/types/thread";
import { useAppSelector, useAppDispatch } from "@/redux/store";
import {
  deleteRoom,
  removeAlertDialog,
  deleteComment,
  deleteGeneration,
  updateSafeSearch,
  setSelectedModel,
  cancelSubscription,
} from "@/redux/actions";
import Dialog from "../Dialog";

const AlertDialog = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const alerts = useAppSelector((state) => state.alert.alertDialogs);

  const handleContinue = (id: string, type: AlertDialogType, data: any) => {
    dispatch(removeAlertDialog(id));
    handleNext(type, data);
  };

  const handleCancel = (id: string, type: AlertDialogType, data: any) => {
    dispatch(removeAlertDialog(id));
    handlePrev(type, data);
  };

  function handlePrev(type: AlertDialogType, data?: any) {
    switch (type) {
      case AlertDialogType.AccountRequired:
      case AlertDialogType.PasswordResetSuccess: {
        router.push("/signin");
        break;
      }
      default:
        break;
    }
  }

  async function handleNext(type: AlertDialogType, data?: any) {
    switch (type) {
      case AlertDialogType.AccountRequired: {
        router.push("/register");
        break;
      }
      case AlertDialogType.DeleteRoom: {
        await dispatch(deleteRoom(data as number));
        router.push("/");
        break;
      }
      case AlertDialogType.DeleteComment: {
        dispatch(deleteComment(data));
        break;
      }
      case AlertDialogType.DeleteGeneration: {
        dispatch(deleteGeneration(data));
        break;
      }
      case AlertDialogType.QuotaLimitReached:
      case AlertDialogType.SubscriptionRequired: {
        router.push("/subscribe");
        break;
      }
      case AlertDialogType.ToggleSafeSearch: {
        dispatch(updateSafeSearch(false));
        break;
      }
      case AlertDialogType.Unsubscribe: {
        dispatch(cancelSubscription());
        break;
      }
      case AlertDialogType.UpdateAvailable: {
        if (typeof window !== "undefined") {
          window.location.reload();
        }
        break;
      }
      case AlertDialogType.UpdateSelectedModel: {
        const newValue = data as TransformationType;
        dispatch(setSelectedModel(newValue));
        if (typeof localStorage !== "undefined") {
          localStorage.setItem("selectedModel", newValue);
        }
        break;
      }
      default:
        break;
    }
  }

  return (
    <div>
      {alerts.map((alert) => (
        <Dialog
          key={`alert-dialog-${alert.id}`}
          id={alert.id}
          title={alert.title}
          description={alert.description}
          prev={() => handleCancel(alert.id, alert.type, alert.data)}
          next={() => handleContinue(alert.id, alert.type, alert.data)}
          onClose={() => dispatch(removeAlertDialog(alert.id))}
          prevButtonText={alert.prevButtonText}
          nextButtonText={alert.nextButtonText}
          singleOption={alert.singleOption}
        />
      ))}
    </div>
  );
};

export default AlertDialog;

"use client";

import MUI_ALERT from "@mui/material/Alert";

import { useStyles } from "./styles";
import { useAppSelector, useAppDispatch } from "@/redux/store";
import { removeAlert } from "@/redux/actions";

const Alerts = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const alerts = useAppSelector((state) => state.alert.alerts);

  return (
    <div className={classes.container}>
      {alerts.length > 0 &&
        alerts.map((alert) => (
          <div key={alert.id} className={classes.alert}>
            <MUI_ALERT
              severity={alert.type}
              onClose={() => dispatch(removeAlert(alert.id))}
            >
              {alert.text}
            </MUI_ALERT>
          </div>
        ))}
    </div>
  );
};

export default Alerts;

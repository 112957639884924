import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<{ overflowParent: boolean }>()(
  (theme, { overflowParent }) => ({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    mainControls: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      justifyContent: "center",
      width: "100%",
      maxWidth: 1000,
      background: "#ffffff",
      [theme.breakpoints.up("lg")]: {
        flexDirection: "row",
        gap: theme.spacing(5),
      },
    },
    inputContainer: {
      flex: 1,
      maxHeight: overflowParent ? 35 : "auto",
      background: "#ffffff",
    },
    bottomControls: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  })
);

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    fontSize: "0.8rem",
  },
}));

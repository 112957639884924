import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  container: {
    position: "absolute",
    top: theme.spacing(8),
    right: theme.spacing(4),
    zIndex: 9999,
  },
  alert: {
    width: "100%",
    marginTop: theme.spacing(4),
  },
}));

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles<
  {
    isActive: boolean;
    disabled: boolean;
    scale: number;
  },
  "icon" | "activeIcon"
>()((theme, { isActive, disabled, scale }, classes) => ({
  container: {
    all: "unset",
    cursor: disabled ? "not-allowed" : "pointer",
    "&:hover": {
      [`& .${classes.icon}`]: {
        display: disabled ? "grid" : "none",
      },
      [`& .${classes.activeIcon}`]: {
        display: disabled ? "none" : "grid",
      },
    },
  },
  icon: {
    display: isActive ? "none" : "grid",
    placeItems: "center",
    color: theme.palette.primary.main,
    "& > svg": {
      width: 18 * scale,
      height: 18 * scale,
    },
    [theme.breakpoints.up("lg")]: {
      "& > svg": {
        width: 20 * scale,
        height: 20 * scale,
      },
    },
  },
  activeIcon: {
    display: isActive && !disabled ? "grid" : "none",
    placeItems: "center",
    color: isActive ? theme.palette.secondary.main : theme.palette.primary.main,
    "& > svg": {
      width: 18 * scale,
      height: 18 * scale,
    },
    [theme.breakpoints.up("lg")]: {
      "& > svg": {
        width: 20 * scale,
        height: 20 * scale,
      },
    },
  },
  disabled: {
    opacity: 0.5,
    color: theme.palette.text.disabled,
  },
}));

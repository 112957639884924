"use client";

import { usePathname } from "next/navigation";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material/styles";
import {
  FaDiscord,
  FaPinterest,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { MdMail } from "react-icons/md";
import dayjs from "dayjs";

import { useStyles } from "./styles";
import RequestFeatureButton from "./FeatureRequestButton";

const Footer = () => {
  const { classes } = useStyles();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const pathname = usePathname() as string;

  if (pathname === "/subscribe" && !isDesktop) return null;

  return (
    <footer className={classes.container}>
      <div className={classes.links}>
        <p className={classes.copyright}>&copy; Idyllic {dayjs().year()}</p>
        <a
          href="https://idyllic.app/privacy-policy"
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          Privacy Policy
        </a>
        <RequestFeatureButton />
      </div>
      <div className={classes.links}>
        <a
          href="mailto:hello@idyllic.app"
          target="_blank"
          rel="noreferrer"
          className={classes.iconLink}
          aria-label="View Idyllic's LinkedIn profile"
        >
          <MdMail />
        </a>
        <a
          href="https://discord.gg/unmynGrMTf"
          target="_blank"
          rel="noreferrer"
          className={classes.iconLink}
          aria-label="Go to the Idyllic Discord channel"
        >
          <FaDiscord />
        </a>
        <a
          href="https://twitter.com/Idyllicapp"
          target="_blank"
          rel="noreferrer"
          className={classes.iconLink}
          aria-label="View Idyllic's Twitter profile"
        >
          <FaXTwitter />
        </a>
        <a
          href="https://www.pinterest.com/idyllicapp/"
          target="_blank"
          rel="noreferrer"
          className={classes.iconLink}
          aria-label="View Idyllic's Pinterest profile"
        >
          <FaPinterest />
        </a>
        <a
          href="https://www.instagram.com/idyllic.app/"
          target="_blank"
          rel="noreferrer"
          className={classes.iconLink}
          aria-label="View Idyllic's Instagram profile"
        >
          <FaInstagram />
        </a>
        <a
          href="https://www.linkedin.com/company/idyllic-app"
          target="_blank"
          rel="noreferrer"
          className={classes.iconLink}
          aria-label="View Idyllic's LinkedIn profile"
        >
          <FaLinkedin />
        </a>
      </div>
    </footer>
  );
};

export default Footer;

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(4),
  },
  text: {
    textWrap: "balance",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
    },
  },
  promptInput: {
    maxWidth: 500,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  goButton: {
    width: 200,
    height: 40,
    fontSize: "1.25rem",
  },
}));

import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  icon: {
    fontSize: "2rem",
  },
  text: {
    display: "block",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));
